import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./Ace.module.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import { HStack, IconButton, ButtonGroup, Tooltip } from "@chakra-ui/react";
import PopOver from "./Popover";
import {
  getAllBibitems,
  findRangeInMarkers,
  getAllEquations,
  getAllTables,
} from "../utils";
import CitationPopup from "./CitationPopup";
import MathPopup from "./MathPopup";
import { saveTexApi, downloadTexApi } from "../api";
import ImagePopup from "./ImagePopup";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import { InsertMath } from "./InsertMath";
import { diff_match_patch } from "diff-match-patch";
import {
  SaveIcon,
  SearchIcon,
  ReplaceIcon,
  BoldIcon,
  ItalicIcon,
  Auto,
  Resize1,
  Resize2,
  MenuBar,
  MenuClose,
  HideChange,
  ShowChange,
} from "./icons";
import { connect } from "react-redux";
import epl from "../eplstyles";
import { redirect, useNavigate } from "react-router-dom";
import Loading from "./Loading";
// import Tablepopup from "./Tablepopup";

const AceFunctional = forwardRef((props, ref) => {
  const dmp = new diff_match_patch();
  const toast = props.toast;
  const ace = useRef(null);
  // const popup = useRef();
  const [texFile, setTexFile] = useState("");
  const [filename, setFilename] = useState("");
  const [markers, setMarkers] = useState([]);
  const [bibitems, setBibitems] = useState({});
  const [equations, setEquations] = useState({});
  const [tables, setTables] = useState({});
  const [actualValue, setActualValue] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [toggleChange, setToggleChange] = useState(false);
  const [changes, setChanges] = useState([]);
  const [popUp, setPopUp] = useState({
    popup: { x: -100, y: -100, show: false, header: "", body: "", type: "" },
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  // Getting actual and correction file from api

  useEffect(() => {
    const tex = async () => {
      const file = await downloadTexApi();
      try {
        setTexFile(file.data.tex);
        setActualValue(file.data.proof_tex);
        setFilename(file.data.file);
        props.setFileName(file.data.file);
      } catch (error) {
        setTexFile("No File Found");
        setLoading(false);
        return navigate("home");
      } finally {
        setLoading(false);
      }

      setTimeout(() => {
        enableMarkers();
        ace.current.editor.session.getUndoManager().reset();
      }, 2000);
    };
    tex();

    ace.current.editor.setOption("enableMultiselect", false);
    // window.ace = ace.current;
  }, []);

  useEffect(() => {
    onChange(texFile);
  }, [actualValue, texFile]);

  useEffect(() => {
    props.uploadtex(texFile);
  }, [changes]);

  // sending ref from child to parent component

  useImperativeHandle(ref, () => ({
    editor: ace?.current?.editor,
  }));

  // useeffect to scrolltoline in PE/CE Tools;

  useEffect(() => {
    if (props.selection) {
      let start = props.selection.start;
      ace.current.editor.session.selection.setSelectionRange(props.selection);
      ace.current.editor.scrollToLine(start.row);
    }
  }, [props.selection]);

  // useeffect for showing deleted text on hover

  useEffect(() => {
    if (changes) {
      ace.current.editor.on("mousemove", showDeletedOnHover);
    }
    return () => {
      if (ace.current) {
        ace.current.editor.off("mousemove", showDeletedOnHover);
      }
    };
  }, [changes, popUp]);

  const showDeletedOnHover = (event) => {
    let cursor = event.getDocumentPosition();

    let marker = changes;

    for (let mark of marker) {
      if (
        mark.startRow === cursor.row &&
        mark.startCol === cursor.column &&
        mark.className.indexOf("Ace_deleted_marker") === 0
      ) {
        setPopUp({
          popup: {
            x: event.x,
            y: event.y,
            show: true,
            header: mark.text,
            type: "deletion",
          },
        });
        return;
      }
      if (popUp.popup.show && popUp.popup.type === "deletion") {
        hidePopover();
      }
    }
  };

  // useeffect for showing the markers on selection
  useEffect(() => {
    if (markers) {
      ace.current.editor.on("mouseup", processSelection);
    }
    return () => {
      if (ace.current) {
        ace.current.editor.off("mouseup", processSelection);
      }
    };
  }, [markers]);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "T") {
        event.preventDefault();
      }
    });
  }, []);

  // useeffect for shortcut key bindings
  useEffect(() => {
    ace.current.editor.commands.addCommand({
      name: "TextBold",
      bindKey: {
        win: "Ctrl-B",
        mac: "Command-B",
      },
      exec: textToBold,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "TextItalic",
      bindKey: {
        win: "Ctrl-I",
        mac: "Command-I",
      },
      exec: textToItalics,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "lowercase",
      bindKey: {
        win: "Alt-L",
        mac: "Alt-L",
      },
      exec: toLowerCase,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "nobreak",
      bindKey: {
        win: "Alt-B",
        mac: "Command-Option-B",
      },
      exec: noBreak,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "hBox",
      bindKey: {
        win: "Ctrl-Shift-H",
        mac: "Command-Shift-H",
      },
      exec: hBox,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "Latex Compile",
      bindKey: {
        win: "Ctrl-L",
        mac: "Command-L",
      },
      exec: props.compileTex,
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "Track Latex Compile",
      bindKey: {
        win: "Ctrl-K",
        mac: "Command-K",
      },
      exec: props.compileTrack,
      readOnly: true,
    });
    // const test = (a, b) => {
    //   console.log(a, b);
    // };
    // ace.current.editor.commands.addCommand({
    //   name: "shortcuts",
    //   bindKey: {
    //     win: "Ctrl-1|Ctrl-2",
    //   },
    //   exec: test,
    //   readOnly: true,
    // });

    ace.current.editor.commands.addCommand({
      name: "looseness-1",
      bindKey: {
        win: "Ctrl-1",
        mac: "Ctrl-1",
      },
      exec: () => customshortcuts(1),
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "vfill\\eject",
      bindKey: {
        win: "Ctrl-2",
        mac: "Ctrl-2",
      },
      exec: () => customshortcuts(2),
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "unskip\\break",
      bindKey: {
        win: "Ctrl-3",
        mac: "Ctrl-3",
      },
      exec: () => customshortcuts(3),
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "custom shortcuts",
      bindKey: {
        win: "Ctrl-4",
        mac: "Ctrl-4",
      },
      exec: () => customshortcuts(4),
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "custom shortcuts",
      bindKey: {
        win: "Ctrl-5",
        mac: "Ctrl-5",
      },
      exec: () => customshortcuts(5),
      readOnly: true,
    });
    ace.current.editor.commands.addCommand({
      name: "custom shortcuts",
      bindKey: {
        win: "Ctrl-6",
        mac: "Ctrl-6",
      },
      exec: () => customshortcuts(6),
      readOnly: true,
    });
  });

  // useeffect for searching the text in pdf on selection in editor
  useEffect(() => {
    let curSearch = props.latexsearch;
    if (curSearch && curSearch.length > 3 && curSearch !== lastSearch) {
      setSearchFromPdf(curSearch);
      setLastSearch(curSearch);
    }
  }, [props.latexsearch]);

  // function to search the text on editor on selection in pdf
  const setSearchFromPdf = (search) => {
    ace.current.editor.find(search);
  };
  // function to hidepopups

  const hidePopover = () => {
    setPopUp({ popup: { show: false } });
  };

  // list of function to get the markers from the tex file.

  // const getCitationMarkers = (mode) => {
  //   let txt = mode === "image" ? "ref" : "cite";
  //   var className = mode === "image" ? styles.reference : styles.citation;
  //   ace.current.editor.$search.$options = {
  //     needle: txt + "{(.*?)}",
  //     wrap: true,
  //     caseSensitive: false,
  //     wholeWord: false,
  //     regExp: true,
  //     preventScroll: true, // do not change selection
  //   };
  //   var res = ace.current.editor.$search.findAll(ace.current.editor.session);
  //   var allMarkers = [];
  //   for (var r of res) {
  //     var text = ace.current.editor.session.getTextRange(r);
  //     text = text.replace(txt + "{", "");
  //     var tend = text.length;
  //     text = text.replace("}", "");
  //     var commaSeparated = text.split(",");
  //     var st = txt.length + 1;
  //     console.log(commaSeparated);
  //     var end = 0;
  //     for (var i = 0; i < commaSeparated.length; i++) {
  //       end += commaSeparated[i].length;
  //       allMarkers.push({
  //         startRow: r.start.row,
  //         startCol: r.start.column + st,
  //         endRow: r.end.row,
  //         endCol: r.end.column - (tend - end - i),
  //         className: className,
  //         type: "text",
  //         text: commaSeparated[i].trim(),
  //       });
  //       st = st + commaSeparated[i].length + 1;
  //     }
  //   }
  //   return allMarkers;
  // };

  const getCitationMarkers = (mode) => {
    let txt;
    var className;
    let typ;
    if (mode === "image") {
      txt = /(Fig|Figure)\.*\~*\\ref{(.*?)}/gi;
      className = styles.reference;
    } else if (mode === "Table") {
      txt = /Table\.*\~*\\ref{(.*?)}/gi;
      className = styles.table_cit;
    } else if (mode === "equation") {
      txt = /(equation|eq|eqs)*\.*\~*\(*(\\eqref|\\ref){(.*?)}\)*/gi;
      className = styles.equ_cit;
    } else {
      txt = /\\cite{(.*?)}/gi;
      className = styles.citation;
    }
    if (ace.current.editor) {
      ace.current.editor.$search.$options = {
        needle: txt,
        wrap: true,
        caseSensitive: false,
        wholeWord: false,
        regExp: true,
        preventScroll: true, // do not change selection
      };
    }
    var res = ace.current.editor.$search.findAll(ace.current.editor.session);
    var allMarkers = [];
    for (var r of res) {
      var text = ace.current.editor.session.getTextRange(r);
      if (/(Fig|Figure)\.*\~*\\ref{/i.test(text)) {
        typ = text.match(/(Fig|Figure)\.*\~*\\ref{/i);
        text = text
          .replace(/(Fig|Figure)\.*\~*\\ref{/i, "")
          .replace(/\}\)*/, "");
      } else if (/(equation|eq|eqs)*\.*\~*\(*(\\ref|\\eqref){/i.test(text)) {
        typ = text.match(/(equation|eq|eqs)*\.*\~*\(*(\\ref|\\eqref){/i);
        text = text
          .replace(/(equation|eq|eqs)*\.*\~*\(*(\\ref|\\eqref){/i, "")
          .replace(/\}\)*/, "");
      } else if (/Table\.*\~*\\ref{/i.test(text)) {
        typ = text.match(/Table\.*\~*\\ref{/i);
        text = text.replace(/Table\.*\~*\\ref{/i, "").replace(/\}\)*/, "");
      } else if (/\\cite{/i.test(text)) {
        typ = text.match(/\\cite{/i);
        text = text.replace(/\\cite{/i, "").replace(/\}\)*/, "");
      } else {
      }
      var tend = text.length;
      var commaSeparated = text.split(",");
      var st = typ[0].length;

      // console.log(r);
      var end = 0;
      for (var i = 0; i < commaSeparated.length; i++) {
        end += commaSeparated[i].length;
        allMarkers.push({
          startRow: r.start.row,
          startCol: r.start.column + st,
          endRow: r.end.row,
          endCol: r.end.column - (tend - (end - 1) - i),
          className: className,
          type: "text",
          text: commaSeparated[i].trim(),
        });
        st = st + commaSeparated[i].length + 1;
      }
    }
    return allMarkers;
  };

  const querySelector = () => {
    ace.current.editor.$search.$options = {
      needle: /\\[AQ]\w+({.*?})(\{.*?\})?/g,
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true,
    };
    var res = ace.current.editor.$search.findAll(ace.current.editor.session);

    var allqueries = [];
    res.forEach((element, i) => {
      let question;
      let ans;
      var text = ace.current.editor.session.getTextRange(element);
      var textSplit = text.split("{");

      question = textSplit[1].replace("}", "");
      if (textSplit[2]) {
        ans = textSplit[2].replace("}", "");
      }
      allqueries.push({
        index: i,
        startrow: element.start.row,
        startcol: element.start.column,
        endrow: element.end.row,
        endCol: element.end.column,
        qns: question,
        ans: ans,
      });
    });
    props.querydata(allqueries);
  };

  const getInlineEqnMarkers = () => {
    ace.current.editor.$search.$options = {
      needle: "\\$(.*?)\\$",
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true, // do not change selection
    };
    var res = ace.current.editor.$search.findAll(ace.current.editor.session);
    var allMarkers = [];
    for (var r of res) {
      var text = ace.current.editor.session.getTextRange(r);
      text = text.replace(/\$/g, "");
      allMarkers.push({
        startRow: r.start.row,
        startCol: r.start.column + 1,
        endRow: r.end.row,
        endCol: r.end.column - 1,
        className: styles.inline_equation,
        type: "text",
        text: text,
      });
    }
    return allMarkers;
  };

  const getEqnMarkers = () => {
    var maxLines = 10;
    ace.current.editor.$search.$options = {
      needle: /\\begin\{eq.*/g,
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true, // do not change selection
    };
    var res = ace.current.editor.$search.findAll(ace.current.editor.session);
    var allMarkers = [];
    for (var r of res) {
      var found = false;
      var text = ace.current.editor.session.getTextRange(r);
      //ace.current.editor.session.getTextRange({start:{row:5}, end:{row:5}})
      for (var i = 1; i < maxLines; i++) {
        var curText = ace.current.editor.session.getLine(r.start.row + i);
        text += "\r\n" + curText;
        if (curText.indexOf("\\end") === 0) {
          found = true;
          break;
        }
      }
      if (found) {
        allMarkers.push({
          startRow: r.start.row,
          startCol: r.start.column,
          endRow: r.start.row + i,
          endCol: curText.length,
          className: styles.equation,
          type: "text",
          text: text,
        });
      }
    }
    return allMarkers;
  };

  // function to enable markers in ace editor
  const enableMarkers = () => {
    if (texFile) {
      setBibitems(getAllBibitems(texFile));
      setEquations(getAllEquations(texFile));
      setTables(getAllTables(texFile));
    }
    // getAllBibitems(texFile);
    var citationMarkers = getCitationMarkers();
    var imageMarkers = getCitationMarkers("image");
    var tableMarkers = getCitationMarkers("Table");
    var equationMarkers = getCitationMarkers("equation");
    var inlineEqnMarkers = getInlineEqnMarkers();
    var eqnMarkers = getEqnMarkers();
    var allMarkers = [].concat(
      citationMarkers,
      inlineEqnMarkers,
      eqnMarkers,
      imageMarkers,
      tableMarkers,
      equationMarkers
    );
    setMarkers(allMarkers);
    querySelector();
  };
  // console.log(equations);

  // function to show markers on selection of text
  const processSelection = (event) => {
    hidePopover();
    var cursorPosition = event.editor.selection.getCursor();
    var selectedMarker = findRangeInMarkers(
      event.editor.getSelectionRange(),
      markers,
      cursorPosition
    );
    if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_citation") === 0
    ) {
      console.log(selectedMarker);
      var bibText = selectedMarker.text;
      var name;
      var review;
      if (bibitems[bibText] == undefined) {
        name = "Citation data not found";
        review = "";
      } else {
        name = bibitems[bibText].name;
        review = bibitems[bibText].review;
      }

      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: bibText,
          body: <CitationPopup name={name} review={review} />,
          type: "citation",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_reference") === 0
    ) {
      let refText = selectedMarker.text;
      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: refText,
          body: <ImagePopup name={refText} toast={toast} />,
          type: "Image",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_inline_equation") === 0
    ) {
      let eqn = selectedMarker.text;
      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: "Math Eqn",
          body: <MathPopup equation={eqn} />,
          type: "matheqn",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_equation") === 0
    ) {
      let eqn = selectedMarker.text;
      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: "Equation",
          body: <MathPopup equation={eqn} />,
          type: "equation",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_equ_cit") === 0
    ) {
      let eqn_cit = selectedMarker.text;
      let selectedeqn;
      console.log(equations);
      console.log(eqn_cit);

      if (eqn_cit.includes("epl")) {
        selectedeqn = equations[eqn_cit].eqn[0];
      } else {
        selectedeqn = "Run styles first";
      }
      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: "Equation",
          body: <MathPopup equation={selectedeqn} />,
          type: "equation",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_table_cit") === 0
    ) {
      let refText = selectedMarker.text;
      refText = refText.replace(/Table/, "");
      let selectedtable = tables[refText].tab[0];

      setPopUp({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: refText,
          body: <MathPopup equation={selectedtable} />,
          type: "Image",
        },
      });
    } else {
      if (event.editor.getSelectedText().length > 3) {
        props.pdfsearch(event.editor.getSelectedText());
      }
    }
  };

  // function to compare changes in the tex file and actual file.
  const onChange = (newValue) => {
    let r = dmp.diff_main(actualValue, newValue);
    let allMarkers = [];
    var st = 0;
    for (var i of r) {
      if (i[0] === 1) {
        let value = i[1];
        let start = ace.current.editor.session.doc.indexToPosition(st);
        let end = ace.current.editor.session.doc.indexToPosition(
          st + value.length
        );
        allMarkers.push({
          // time: time,
          startRow: start.row,
          startCol: start.column,
          endRow: end.row,
          endCol: end.column,
          className: styles.added_marker,
          type: "text",
          text: value,
        });
        st = st + value.length;
      } else if (i[0] === -1) {
        let value = i[1];
        let start = ace.current.editor.session.doc.indexToPosition(st);
        let end = ace.current.editor.session.doc.indexToPosition(st);
        allMarkers.push({
          // time: time,
          startRow: start.row,
          startCol: start.column,
          endRow: end.row,
          endColumn: end.column,
          className: styles.deleted_marker,
          type: "background",
          text: value,
        });
      } else {
        st = st + i[1].length;
      }
    }

    setTexFile(newValue);

    setChanges(allMarkers);

    props.exampleAction(allMarkers);

    enableMarkers();
    return true;
  };

  // function to insert Math equations in texfile
  const insertMath = (text) => {
    ace.current.editor.session.insert(
      ace.current.editor.getCursorPosition(),
      text
    );
  };

  // function to save texfile
  const saveTex = async () => {
    var result = await saveTexApi(texFile, changes);
    if (result.data) {
      props.toast({
        title: "Saving Tex.",
        description: "Save successfull.",
        status: "success",
        duration: 1000,
      });
    }
  };

  // list of functions to enable the shortcut keybindings
  const textToBold = () => {
    var selectedrange = ace.current.editor.getSelectionRange();
    let selectedtext = ace.current.editor.getSelectedText();

    ace.current.editor.session.replace(
      selectedrange,
      "\\textbf{" + selectedtext + "}"
    );
  };
  const textToItalics = () => {
    var selectedrange = ace.current.editor.getSelectionRange();
    let selectedtext = ace.current.editor.getSelectedText();

    ace.current.editor.session.replace(
      selectedrange,
      "\\textit{" + selectedtext + "}"
    );
  };
  const toLowerCase = () => {
    var selectedrange = ace.current.editor.getSelectionRange();
    let selectedtext = ace.current.editor.getSelectedText();
    ace.current.editor.session.replace(
      selectedrange,
      selectedtext.toLowerCase()
    );
  };
  const noBreak = () => {
    var selectedrange = ace.current.editor.getSelectionRange();
    let selectedtext = ace.current.editor.getSelectedText();
    ace.current.editor.session.replace(
      selectedrange,
      `\\nobreak{${selectedtext}}`
    );
  };
  const hBox = () => {
    var selectedrange = ace.current.editor.getSelectionRange();
    let selectedtext = ace.current.editor.getSelectedText();
    ace.current.editor.session.replace(
      selectedrange,
      `\\hbox{${selectedtext}}`
    );
  };

  const customshortcuts = (num) => {
    let current = JSON.parse(localStorage.getItem("shortcuts"));
    var cursorPosition = ace.current.editor.getCursorPosition();
    if (num === 1) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut1);
    } else if (num === 2) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut2);
    } else if (num === 3) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut3);
    } else if (num === 4) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut4);
    } else if (num === 5) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut5);
    } else if (num === 6) {
      ace.current.editor.session.insert(cursorPosition, current.shortcut6);
    }
    return true;
  };

  const AutoCorrect = () => {
    toast({ title: "Feature yet to be Implemented" });
  };

  // useeffect to apply copy editing styles.

  useEffect(() => {
    if (!props.editing) {
      // toast({ title: "Error in applying styles" });
      return;
    }
    var currentstyle;
    var tempFile = texFile;
    if (tempFile !== "No File Found") {
      props.editing.map((i) => {
        currentstyle = epl[i].name;
        tempFile = epl[i].action(tempFile, filename, toast);
      });
      toast({
        title: "Applying Styles",
        description: currentstyle,
        duration: 1500,
      });
      setTexFile(tempFile);
    } else {
      toast({
        title: "Error",
        description: "Please Load file to continue.",
        duration: 1500,
      });
    }
  }, [props.editing]);

  return (
    <>
      <div>
        <Loading loading={loading} />
        <span>
          <HStack
            backgroundColor="#F26722"
            justifyContent="space-between"
            maxH="48px"
            minH="48px"
          >
            <ButtonGroup spacing="1">
              {props.leftController ? (
                <Tooltip label="Close panel">
                  <IconButton
                    onClick={() => {
                      props.toggleLeftPanel();
                    }}
                    size="lg"
                    colorScheme="#F26722"
                    aria-label="Close Left Panel"
                    icon={<MenuClose />}
                    _hover={{ bg: "#E05E1D" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip label="Open panel">
                  <IconButton
                    onClick={() => {
                      props.toggleLeftPanel();
                    }}
                    size="lg"
                    colorScheme="#F26722"
                    aria-label="Open Left Panel"
                    icon={<MenuBar />}
                    _hover={{ bg: "#E05E1D" }}
                  />
                </Tooltip>
              )}
              <Tooltip label="Save">
                <IconButton
                  onClick={saveTex}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Save Tex File"
                  icon={<SaveIcon />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
              <Tooltip label="Search">
                <IconButton
                  onClick={() => ace.current.editor.execCommand("find")}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Search inside document"
                  icon={<SearchIcon />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
              <Tooltip label="Replace">
                <IconButton
                  onClick={() => ace.current.editor.execCommand("replace")}
                  color="white"
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Replace inside document"
                  icon={<ReplaceIcon />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>

              <InsertMath insertMath={insertMath} />

              <Tooltip label="Text to Bold">
                <IconButton
                  onClick={textToBold}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Text to Bold"
                  icon={<BoldIcon />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
              <Tooltip label="Text to Italic">
                <IconButton
                  onClick={textToItalics}
                  fontSize="l"
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Text to Italic"
                  icon={<ItalicIcon />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
              <Tooltip label="SpellCheck">
                <IconButton
                  onClick={AutoCorrect}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Autocorrect"
                  icon={<Auto />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
              <Tooltip label="Show/Hide Changes">
                <IconButton
                  onClick={() => setToggleChange(!toggleChange)}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="Show/Hide Changes"
                  icon={toggleChange ? <HideChange /> : <ShowChange />}
                  _hover={{ bg: "#E05E1D" }}
                />
              </Tooltip>
            </ButtonGroup>
            {props.pdfviewer ? (
              <Tooltip label="Expand Editor">
                <IconButton
                  onClick={() => {
                    props.togglePDF();
                  }}
                  size="lg"
                  colorScheme="#f47c0d"
                  aria-label="toogle-pdf"
                  position="absolute"
                  right="0"
                  z-index="2"
                  bg="#F26722"
                  icon={<Resize1 />}
                  _hover={{ bg: "#E05E1D" }}
                ></IconButton>
              </Tooltip>
            ) : (
              <Tooltip label="Minimise editor">
                <IconButton
                  onClick={() => {
                    props.togglePDF();
                  }}
                  size="lg"
                  position="absolute"
                  right="0"
                  colorScheme="#f47c0d"
                  aria-label="toogle-pdf"
                  icon={<Resize2 />}
                  _hover={{ bg: "#E05E1D" }}
                ></IconButton>
              </Tooltip>
            )}
          </HStack>

          <PopOver state={popUp} onClick={() => hidePopover()} />

          <AceEditor
            // onMouseMove={showDeletedOnHover}
            ref={ace}
            markers={!toggleChange ? markers.concat(changes) : markers}
            mode="latex"
            theme="xcode"
            name="ace_editor"
            wrapEnabled={true}
            editorProps={{ $blockScrolling: true }}
            options={{ enableMultiselect: false }}
            fontSize={18}
            onChange={onChange}
            value={texFile}
            enableSnippets={true}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            width="100%"
            height="100vh"
          />
        </span>
      </div>
    </>
  );
});

//Dispatch state to props redux
const mapStateToProps = (state, ownProps) => ({
  data: state.correction.corrections,
  latexsearch: state.latexsearch.latexSearch,
  selection: state.toolsearch.toolSearch,
  query: state.queries.queries,
  editing: state.eplStyles.function,
  compileTex: state.compileTex.compile,
  compileTrack: state.trackCompile.trackcompile,
});

//Dispatch action redux
const mapDispatchToProps = (dispatch) => {
  return {
    exampleAction: (data) => dispatch({ type: "EDIT", payload: data }),
    querydata: (data) => dispatch({ type: "QueryEdit", payload: data }),
    uploadtex: (data) => dispatch({ type: "Uploadtex", payload: data }),
    pdfsearch: (data) => dispatch({ type: "pdfSearch", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AceFunctional);
