import styles from "../components/Imageupload.module.css";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  ModalFooter,
  Stack,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { uploadFile } from "../api";

const Imageupload = ({ isOpen, onClose }) => {
  const [file, setFile] = useState();
  // const [preview, setPreview] = useState();
  // const [newname, setNewName] = useState();

  const toast = useToast();

  var objectURL;

  useEffect(() => {
    if (!file) {
      setFile(null);
      return;
    }

    // objectURL = URL.createObjectURL(file);
    // setPreview(objectURL);
  }, [file]);

  useEffect(() => {
    // setPreview(URL.revokeObjectURL(objectURL));
    setFile();
    // setNewName("");
  }, [isOpen, objectURL]);

  const storeFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return setFile(null);
    } else {
      setFile(e.target.files[0]);
    }
  };

  const uploadImage = (file) => {
    if (!file) {
      return;
    }
    // console.log(file);
    if (file) {
      toast({
        title: "Uploading file",
        discription: "Uploading in progress",
        status: "info",
        duration: null,
        icon: (
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        ),
      });
    }
    uploadFile(file)
      .then((res) => {
        console.log(res);
        if (res) {
          toast.closeAll();
          toast({
            title: "Success",
            description: "Upload Successfull",
            status: "success",
            duration: 1000,
          });
          setFile(null);
        }
      })
      .catch((err) => {
        if (err) {
          toast.closeAll();
          toast({
            title: "Error",
            description: "Upload Unsuccessfull, please try again",
            status: "error",
            duration: 1000,
          });
        }
        console.log(err);
      });
  };
  // console.log(newname);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"lg"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Files</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pb={5}>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pb={5}
            >
              <label htmlFor="input" className={styles.labeltag}>
                Choose File
              </label>
              <Input
                className={styles.inputtag}
                id="input"
                p={1}
                type="file"
                multiple
                onChange={storeFile}
              />
              {file != null ? <p>{file.name}</p> : <></>}
            </Box>
            {/* {preview != null ? (
              <Box
                size="lg"
                width="100%"
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <Image
                  boxSize="200px"
                  src={preview}
                  alt="previewImage"
                  fallback="Error loading img"
                  objectFit="contain"
                />
                <Box pt={5}>
                  <Input
                    type="text"
                    placeholder="Enter image name to be given"
                    onChange={(e) => setNewName(e.target.value)}
                  ></Input>
                </Box>
              </Box>
            ) : (
              <Box
                size="lg"
                width="100%"
                justifyContent="center"
                display="flex"
              >
                <Text fontSize={"sm"} color="#666666">
                  Upload a file to preview
                </Text>
              </Box>
            )} */}
          </Box>
        </ModalBody>
        <ModalFooter display="flex" alignItems="center">
          <Stack direction="row" spacing={4}>
            {file != null ? (
              <Button colorScheme="blue" onClick={() => uploadImage(file)}>
                Upload
              </Button>
            ) : (
              <Button isDisabled>Upload</Button>
            )}
            <Button onClick={onClose}>Close</Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Imageupload;
