import React from "react";
import styles from "./Ace.module.css";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Button,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";

const PopOver = ({ state, onClick }) => {
  // console.log(state);
  return (
    <Popover isOpen={state.popup.show}>
      <PopoverTrigger>
        <Button
          style={{ left: state.popup.x, top: state.popup.y }}
          className={styles.popup_button}
          ref={state.popup}
        >
          Trigger
        </Button>
      </PopoverTrigger>
      <PopoverContent style={{ width: "fit-content" }}>
        <PopoverArrow />
        {state.popup.body ? <PopoverCloseButton onClick={onClick} /> : <></>}
        {state.popup.header ? (
          <PopoverHeader style={{ marginRight: "20px" }}>
            {state.popup.header}
          </PopoverHeader>
        ) : (
          <></>
        )}
        {state.popup.body ? (
          <PopoverBody>{state.popup.body}</PopoverBody>
        ) : (
          <></>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default PopOver;
