import React from "react";
import styles from "./Publishernew.module.css";

const Publishernew = ({ isTablet }) => {
  return (
    <div className={styles.advantageouter}>
      <div className={styles.content}>
        {isTablet ? (
          <></>
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/Redesignpics/3.svg"}
            alt="number_three_img"
          />
        )}
        <div className={styles.maincontent}>
          <div className={styles.advantageimg}>
            <img
              alt="advantageimg"
              src={
                process.env.PUBLIC_URL + "/images/Redesignpics/feature3.webp"
              }
            />
          </div>
          <div className={styles.advantage_list}>
            <div className={styles.advpara}>
              <p>
                EditLaTeX can be used by publishers for any of their vendors. As
                a special feature we have designed this to be used by any of
                your existing vendors. For this, the typesetter should just load
                the LaTeX, PDF and image files into our cloud. Once it is done,
                we would generate and return the unique link back to you or your
                typesetter to send it to the author. Once the author submits the
                proofed file, our system would validate and send back the LaTeX,
                PDF, XML, Authors’ response to queries, list of corrections,
                attachments and any other specifics that are needed for your
                journals/books. As an added feature, we would generate and
                include XML in the package if the vendors do not have XML first
                workflow.
              </p>
            </div>
            {/* <div className={styles.list}>
              <ul>
                {data.map((i, index) => {
                  return <li key={index}>{i.text}</li>;
                })}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publishernew;
