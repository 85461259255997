import React from "react";
import styles from "./GetInTouch.module.css";
import { Heading } from "@chakra-ui/react";
import { BsTelephoneFill } from "react-icons/bs";
import Form from "./Form";

const GetInTouch = ({ isMobile, isTablet }) => {
  return (
    <div className={styles.connect_outer}>
      <div className={styles.connect_heading}>
        <Heading
          size="xl"
          style={{
            fontFamily: "Georgia",
            fontWeight: "400",
          }}
        >
          Get In Touch
        </Heading>
      </div>
      <div className={styles.connect_details}>
        <div className={styles.connect_list}>
          <div className={styles.connectlist1}>
            <div className={styles.heading}>
              <p>India</p>
            </div>
            <div className={styles.break}>
              <hr></hr>
            </div>
            <div className={styles.address}>
              <p>
                Transforma Pvt Ltd (Corporate Office)
                <br></br>
                #134A/140 Balaji Arcade, Arcot Road,
                <br></br>
                Chennai | India – 600 092
              </p>
            </div>
            <div className={styles.phone}>
              <BsTelephoneFill />
              <a href="tel:+91 9884006293">+91 9884006293</a>
            </div>
            <div className={styles.mail}>
              <img
                className={styles.mailimg}
                alt="mail-icon"
                src={process.env.PUBLIC_URL + "/images/Redesignpics/mail.png"}
              />
              <a className={styles.indmail} href="mailto:info@transforma.in">
                murali@transforma.in
              </a>
            </div>
            <div className={styles.delivery}>
              <p className={styles.headingdelivery}>Delivery Unit</p>
              <div className={styles.breakdelivery}>
                <hr></hr>
              </div>
              <p className={styles.deliveryadd}>
                #55, V.V Nagar, V.M. Chatram, Tirunelveli – 627 011
              </p>
            </div>
          </div>
          <div className={styles.connectlist1}>
            <div className={styles.heading}>
              <p>USA</p>
            </div>
            <div className={styles.break}>
              <hr></hr>
            </div>
            <div className={styles.address}>
              <p>
                Transforma Inc
                <br></br>
                2015 Lincoln HWY, Suite # 210,
                <br></br>
                Edison, NJ 08817, USA.
              </p>
            </div>
            <div className={styles.phone}>
              <BsTelephoneFill />
              <a href="tel:+1 9083154177">+1 908 315 4177</a>
            </div>
            <div className={styles.mailaddress}>
              <p className={styles.headingdelivery}>Dr. Mirko</p>
              <div className={styles.breakdelivery}>
                <hr></hr>
              </div>
              <div className={styles.mail}>
                <img
                  className={styles.mailimg}
                  alt="mail-icon"
                  src={process.env.PUBLIC_URL + "/images/Redesignpics/mail.png"}
                />
                <a
                  className={styles.usmail}
                  href="mailto:mirko.janc@transforma.in"
                >
                  mirko.janc@transforma.in
                </a>
              </div>
            </div>
            <div className={styles.mailaddress1}>
              <p className={styles.headingdelivery}>Balaji TV</p>
              <div className={styles.breakdelivery}>
                <hr></hr>
              </div>
              <div className={styles.mail}>
                <img
                  className={styles.mailimg}
                  alt="mail-icon"
                  src={process.env.PUBLIC_URL + "/images/Redesignpics/mail.png"}
                />
                <a
                  className={styles.usmail}
                  href="mailto:balaji.tv@transforma.in"
                >
                  balaji.tv@transforma.in
                </a>
              </div>
            </div>
          </div>
          <div className={`${styles.connectlist1} ${styles.connectlist3}`}>
            <div className={styles.heading}>
              <p>Germany</p>
            </div>
            <div className={styles.break}>
              <hr></hr>
            </div>
            <div className={styles.address}>
              <p>
                Wilhelm-Schrohe-Straße 72
                <br></br>
                55128 Mainz, Germany
              </p>
            </div>
            <div className={styles.delivery}>
              <p className={styles.headingdelivery}>Sunil Shah</p>
              <div className={styles.breakdelivery}>
                <hr></hr>
              </div>
              <div className={styles.phone3}>
                <BsTelephoneFill />
                <a href="tel:+49 151 52534628">+49 151 52534628</a>
              </div>

              <div className={styles.mail3}>
                <img
                  className={styles.mailimg}
                  alt="mail-icon"
                  src={process.env.PUBLIC_URL + "/images/Redesignpics/mail.png"}
                />
                <a
                  className={styles.germail}
                  href="mailto:sunil.shah@linus-it.com"
                >
                  sunil.shah@linus-it.com
                </a>
                {/* <p className={styles.germail}>sunil.shah@linus-it.com</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.form}>
        <Form isMobile={isMobile} isTablet={isTablet} />
      </div>
    </div>
  );
};

export default GetInTouch;
