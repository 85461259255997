import React from "react";
import styles from "../pages/FAQ.module.css";
import { Heading } from "@chakra-ui/react";
import Questions from "./pagecomponents/Questions";

const FAQ = ({ isMobile }) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  let questions = [
    {
      question: "How to use EditLaTeX for proofing?",
      answer:
        "Edit LaTex can be used to insert corrections directly into LaTeX, compile the file, preview the corrected PDF after compilation, and to view the queries and answer them.",
    },
    {
      question: "Who can use EditLaTeX?",
      answer:
        "Authors who are both novice and experienced in LaTeX can use Edit LaTeX. Edit Latex is developed with functionalities to handle both heavy mathematical content and text corrections.",
    },
    {
      question: "Can authors who do not know LaTeX use EditLaTeX?",
      answer:
        "Yes, authors who are still learning the ways of TeX can also use EditLaTeX easily.",
    },
    {
      question:
        "Can EditLaTeX be used on all platforms like Mac and Windows and on any gadgets alike?",
      answer:
        "EditLaTeX is embedded with features that enable it to be used across all platforms and gadgets.",
    },
    {
      question: "Can EditLaTeX be used for any format of content?",
      answer:
        "Yes, it can support any format and any layout and all sorts of content.",
    },
    {
      question: "Can EditLaTeX also be used for book proofing?",
      answer:
        "EditLaTeX can be used not only for journals but also for books with all the features.",
    },
    {
      question: "Why EditLateX is better than any other proofing tool?",
      answer:
        "EditLaTeX is unique as the corrections can be done directly on LaTeX code and the changes can be viewed in PDF in parallel in a single window.",
    },
    {
      question: "How will EditLaTeX improve the delivery time of the article?",
      answer:
        "Proofing in EditLaTeX definitely improves delivery time as the PDF, LaTeX and queries are all interlinked and it has provisions for contacting stakeholders.",
    },
    {
      question: "Can queries be resolved easily?",
      answer:
        "The system automatically validates all the query responses and alerts if anything is not attended to, thus avoiding multiple interactions in resolving queries",
    },
    {
      question: "Does EditLaTeX support all the new and modern fonts?",
      answer:
        "EditLaTeX supports all the modern fonts and text in other languages as well",
    },
    {
      question: "How easy is it to handle math in EditLaTeX?",
      answer:
        "EditLaTeX is a true expert when it comes to heavy math articles.",
    },
    {
      question: "Can the authors view the final corrected PDF?",
      answer:
        "The authors can view not only the final corrected PDF, but also the updated PDF every time a single correction is made, once the correction is made and LaTeX source is compiled.",
    },
    {
      question: "How can references and other elements be edited?",
      answer:
        "All the elements and references can be viewed in parallel while hovering over the citations and a single click on the reference will make it available to edit.",
    },
    {
      question: "Can authors submit figures and tables along with proofs?",
      answer:
        "Yes, replacement elements or new elements can be submitted along with the proofs",
    },
    {
      question:
        "Can text in other languages and special characters be edited easily in EditLaTeX?",
      answer:
        "Yes, the EditLaTex’s special features allow easy editing of text in other languages and special characters.",
    },
    {
      question: "Can I export or import content from EditLaTeX?",
      answer:
        "The content from EditLaTeX can be imported or exported when it is done by authenticated users.",
    },
    {
      question:
        "What if I want to review all the correction I made? Can I track the changes?",
      answer:
        "EditLaTeX comes with track changes features which allows you to view the changes that are made in various stages.",
    },
    {
      question: "Can I run spellcheck while proofing?",
      answer: "Yes, the spelling can be checked on the go while proofing.",
    },
    {
      question:
        "While editing revises can the author view the older versions too?",
      answer: "EditLaTeX has the option to view the previous version PDFs",
    },
    {
      question:
        "What if there is any compilation error? Any technical support available?",
      answer:
        "Support document is sourced in the tool to help authors in sorting out all aspects of technical errors.",
    },
  ];

  return (
    <div className={styles.faq_outer}>
      <div className={styles.faqcamo}></div>
      <div className={styles.faq_inner}>
        <div className={styles.faq_section}>
          <div className={styles.faq_heading}>
            <Heading
              size="lg"
              className={styles.FAQ_heading}
              fontFamily="Georgia"
              fontWeight={400}
            >
              Frequently Asked Questions
            </Heading>
          </div>
          <div className={styles.questionbox}>
            <div className={styles.image_box}>
              {isMobile ? (
                <img
                  alt="shows people asking questions"
                  src={
                    process.env.PUBLIC_URL + "/images/Redesignpics/FAQMimg.webp"
                  }
                ></img>
              ) : (
                <img
                  alt="shows people asking questions"
                  src={
                    process.env.PUBLIC_URL + "/images/Redesignpics/FAQimg.webp"
                  }
                ></img>
              )}
            </div>
            <div className={styles.questions}>
              <Questions data={questions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
