import React from "react";
import styles from "../pagecomponents/Whoweare.module.css";
import { Heading } from "@chakra-ui/react";

const Whoweare = ({ isMobile }) => {
  return (
    <div className={styles.whoweare_outer}>
      <div className={styles.who_heading}>
        <Heading size="lg" fontFamily="Georgia" fontWeight={400}>
          Who We Are
        </Heading>
      </div>
      {/* {!isMobile ? (
        <div className={styles.who_img}>
          <>
            <div className={styles.who_logo}>
              <img
                alt="company-icons"
                src={process.env.PUBLIC_URL + "/images/Mask group.svg"}
                className={styles.cmp_logo}
              />
            </div>
            <div className={styles.who_logo}>
              <img
                alt="company-icons"
                src={process.env.PUBLIC_URL + "/images/Mask group (1).svg"}
                className={styles.cmp_logo}
              />
            </div>
            <div className={styles.who_logo}>
              <img
                alt="company-icons"
                src={process.env.PUBLIC_URL + "/images/Mask group (2).svg"}
                className={styles.cmp_logo}
              />
            </div>
            <div className={styles.who_logo}>
              <img
                alt="company-icons"
                src={process.env.PUBLIC_URL + "/images/Mask group (3).svg"}
                className={styles.cmp_logo}
              />
            </div>
          </>
        </div>
      ) : (
        <></>
      )} */}

      <div className={styles.who_para}>
        <p style={{ color: "#666666" }}>
          Transforma is an all-encompassing business that offers leading
          publishers a full spectrum of services. Headquartered in Chennai,
          India, and with a front office in the US, we are uniquely positioned
          to offer an array of innovative solutions with a talented,
          resourceful, and experienced global workforce. We are LaTeX experts
          with an extensive knowledge about publishing in all formats. We add
          value to the content by making it precise and rich with cutting edge
          technology. With increasing visibility, we make information discovery
          and dissemination faster in real-time for authors and researchers.
        </p>
      </div>

      {/* {isMobile ? (
        <div className={styles.whoimg_mobile}>
          <img
            alt="company-logos"
            src={process.env.PUBLIC_URL + "/images/whowearemobile.svg"}
          />
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default Whoweare;
