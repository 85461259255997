import styles from "./Ace.module.css";
import React from "react";
import AceEditor from "react-ace";
// import raw from "../tex.txt";
import { HStack, IconButton, ButtonGroup } from "@chakra-ui/react";
import PopOver from "./Popover";
import { getAllBibitems, findRangeInMarkers } from "../utils";
// import {
//   VscReplace,
//   VscSearch,
//   VscMenu,
//   VscSave,
//   VscClose,
//   VscArrowRight,
//   VscArrowLeft,
//   VscBold,
//   VscItalic,
// } from "react-icons/vsc";
import CitationPopup from "./CitationPopup";
import MathPopup from "./MathPopup";
// import { SearchContext } from "../Contexts/AceEditor";
import { saveTexApi, downloadTexApi } from "../api";
import ImagePopup from "./ImagePopup";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import { InsertMath } from "./InsertMath";
// import PdfViewer from "./PdfViewer";
import { diff_match_patch } from "diff-match-patch";
import SplitPaneContext from "../Contexts/SplitPaneContext";

import {
  SaveIcon,
  SearchIcon,
  ReplaceIcon,
  BoldIcon,
  ItalicIcon,
  Auto,
  Resize1,
  Resize2,
  MenuBar,
  MenuClose,
} from "./icons";

class Ace extends React.Component {
  // static contextType = SearchContext;

  constructor(props) {
    super();
    this.state = {
      text: "",
      markers: [],
      track: [],
      props: props,
      popup: { x: -100, y: -100, show: false, header: "", body: "", type: "" },
    };
    this.ace = React.createRef();
    this.popup = React.createRef();
    this.allBibItems = {};
    this.lastSearch = "";
    this.actualValue = "";
    this.dmp = new diff_match_patch();
  }

  componentDidUpdate() {
    var curSearch = this.context.latexSearchText;
    if (curSearch.length > 3 && curSearch !== this.lastSearch) {
      this.setSearchFromPdf(curSearch);
      this.lastSearch = curSearch;
    }
  }

  setSearchFromPdf(searchText) {
    this.ace.current.editor.find(searchText);
  }

  hidePopover() {
    this.setState({ popup: { show: false } });
  }

  async componentDidMount() {
    var result = await downloadTexApi();

    if (result.data) {
      this.actualValue = result.data.proof_tex;
      // console.log(this.actualValue);
      this.setState({ text: result.data.tex });
      setTimeout(() => {
        this.enableMarkers();
        this.ace.current.editor.session.getUndoManager().reset();
        this.onChange(result.data.tex);
      }, 2000);
    }

    this.ace.current.editor.on("mouseup", this.processSelection.bind(this));
    // this.ace.current.editor.on("mouseup", this.testEvent.bind(this));
    // this.ace.current.editor.on("mousedown", this.hidePopover.bind(this));
    this.ace.current.editor.on("mousemove", this.showDeletedOnHover.bind(this));
    this.ace.current.editor.setOption("enableMultiselect", false);
    this.ace.current.editor.commands.addCommand({
      name: "TextBold",
      bindKey: {
        win: "Ctrl-B",
        mac: "Command-B",
      },
      exec: this.textToBold,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "TextItalic",
      bindKey: {
        win: "Ctrl-I",
        mac: "Command-I",
      },
      exec: this.textToItalics,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "lowercase",
      bindKey: {
        win: "Shift-U",
        mac: "Shift-U",
      },
      exec: this.toLowerCase,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "nobreak",
      bindKey: {
        win: "Alt-B",
        mac: "Command-Option-B",
      },
      exec: this.noBreak,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "hBox",
      bindKey: {
        win: "Ctrl-Shift-H",
        mac: "Command-Shift-H",
      },
      exec: this.hBox,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "looseness-1",
      bindKey: {
        win: "Ctrl-1",
        mac: "Ctrl-1",
      },
      exec: this.looseness,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "vfill\\eject",
      bindKey: {
        win: "Ctrl-2",
        mac: "Ctrl-2",
      },
      exec: this.vfill,
      readOnly: true,
    });
    this.ace.current.editor.commands.addCommand({
      name: "unskip\\break",
      bindKey: {
        win: "Ctrl-3",
        mac: "Ctrl-3",
      },
      exec: this.unSkip,
      readOnly: true,
    });
  }

  getCitationMarkers(mode) {
    var txt = mode === "image" ? "ref" : "cite";
    var className = mode === "image" ? styles.reference : styles.citation;
    this.ace.current.editor.$search.$options = {
      needle: txt + "{(.*?)}",
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true, // do not change selection
    };
    var res = this.ace.current.editor.$search.findAll(
      this.ace.current.editor.session
    );
    var allMarkers = [];
    for (var r of res) {
      var text = this.ace.current.editor.session.getTextRange(r);
      text = text.replace(txt + "{", "");
      var tend = text.length;
      text = text.replace("}", "");
      var commaSeparated = text.split(",");
      var st = txt.length + 1;
      var end = 0;
      for (var i = 0; i < commaSeparated.length; i++) {
        end += commaSeparated[i].length;
        allMarkers.push({
          startRow: r.start.row,
          startCol: r.start.column + st,
          endRow: r.end.row,
          endCol: r.end.column - (tend - end - i),
          className: className,
          type: "text",
          text: commaSeparated[i].trim(),
        });
        st = st + commaSeparated[i].length + 1;
      }
    }
    return allMarkers;
  }

  getInlineEqnMarkers() {
    this.ace.current.editor.$search.$options = {
      needle: "\\$(.*?)\\$",
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true, // do not change selection
    };
    var res = this.ace.current.editor.$search.findAll(
      this.ace.current.editor.session
    );
    var allMarkers = [];
    for (var r of res) {
      var text = this.ace.current.editor.session.getTextRange(r);
      text = text.replace(/\$/g, "");
      allMarkers.push({
        startRow: r.start.row,
        startCol: r.start.column + 1,
        endRow: r.end.row,
        endCol: r.end.column - 1,
        className: styles.inline_equation,
        type: "text",
        text: text,
      });
    }
    return allMarkers;
  }

  getEqnMarkers() {
    var maxLines = 10;
    this.ace.current.editor.$search.$options = {
      needle: /^\\begin\{eq.*/g,
      wrap: true,
      caseSensitive: false,
      wholeWord: false,
      regExp: true,
      preventScroll: true, // do not change selection
    };
    var res = this.ace.current.editor.$search.findAll(
      this.ace.current.editor.session
    );
    var allMarkers = [];
    for (var r of res) {
      var found = false;
      var text = this.ace.current.editor.session.getTextRange(r);
      //ace.current.editor.session.getTextRange({start:{row:5}, end:{row:5}})
      for (var i = 1; i < maxLines; i++) {
        var curText = this.ace.current.editor.session.getLine(r.start.row + i);
        text += "\r\n" + curText;
        if (curText.indexOf("\\end") === 0) {
          found = true;
          break;
        }
      }
      if (found) {
        allMarkers.push({
          startRow: r.start.row,
          startCol: r.start.column,
          endRow: r.start.row + i,
          endCol: curText.length,
          className: styles.equation,
          type: "text",
          text: text,
        });
      }
    }
    return allMarkers;
  }

  enableMarkers() {
    window.ace = this.ace;
    window.popup = this.popup;
    this.allBibItems = getAllBibitems(this.state.text);
    var citationMarkers = this.getCitationMarkers();
    var imageMarkers = this.getCitationMarkers("image");
    var inlineEqnMarkers = this.getInlineEqnMarkers();
    var eqnMarkers = this.getEqnMarkers();
    var allMarkers = [].concat(
      citationMarkers,
      inlineEqnMarkers,
      eqnMarkers,
      imageMarkers
    );
    this.setState({ markers: allMarkers });
  }

  getLastRowAndColumn(text) {
    var lines = text.split("\n");
    var lastLine = lines.length - 1;
    var lastColumn = lines[lines.length - 1].length;
    return { row: lastLine, column: lastColumn };
  }

  removeDeletedMarkers(text, markers) {
    var newText = text.split("\n");
    for (var marker of markers) {
      if (marker.className.indexOf("deleted_marker") > 0) {
        var row = marker.startRow;
        newText[row] =
          newText[row].substr(0, marker.startCol) +
          newText[row].substr(marker.endCol);
      }
    }
    return newText.join("\n");
  }

  updateExistingMarker(curMarker, allMarkers) {
    if (curMarker.action === "insert") {
      if (curMarker.start.row === curMarker.end.row) {
        for (var i = 0; i < allMarkers.length; i++) {
          var marker = allMarkers[i];
          if (marker.startRow === curMarker.start.row) {
            if (
              curMarker.start.column > marker.startCol &&
              curMarker.start.column < marker.endCol
            ) {
              marker.endCol =
                marker.endCol + (curMarker.end.column - curMarker.start.column);
            } else if (curMarker.start.column <= marker.startCol) {
              marker.startCol =
                marker.startCol +
                (curMarker.end.column - curMarker.start.column);
              marker.endCol =
                marker.endCol + (curMarker.end.column - curMarker.start.column);
            }
          }
        }
      } else {
        for (let i = 0; i < allMarkers.length; i++) {
          let marker = allMarkers[i];
          if (marker.startRow > curMarker.start.row) {
            marker.startRow =
              marker.startRow + (curMarker.end.row - curMarker.start.row);
            marker.endRow =
              marker.endRow + (curMarker.end.row - curMarker.start.row);
          }
        }
      }
    }
    console.log(allMarkers);
    return allMarkers;
  }

  showDeletedOnHover = (event) => {
    let cursor = event.getDocumentPosition();
    let marker = this.state.track;

    for (var mark of marker) {
      if (
        mark.startRow === cursor.row &&
        mark.startCol === cursor.column &&
        mark.className.indexOf("Ace_deleted_marker") === 0
      ) {
        this.setState({
          popup: {
            x: event.x,
            y: event.y,
            show: true,
            header: mark.text,
            type: "deletion",
          },
        });
        return;
      }
      if (this.state.popup.type === "deletion") {
        this.hidePopover();
      }
    }
  };

  onChange(newValue, event) {
    var r = this.dmp.diff_main(this.actualValue, newValue);
    var allMarkers = [];
    var st = 0;
    for (var i of r) {
      if (i[0] === 1) {
        let value = i[1];
        let start = this.ace.current.editor.session.doc.indexToPosition(st);
        let end = this.ace.current.editor.session.doc.indexToPosition(
          st + value.length
        );
        allMarkers.push({
          startRow: start.row,
          startCol: start.column,
          endRow: end.row,
          endCol: end.column,
          className: styles.added_marker,
          type: "text",
          text: value,
        });
        st = st + value.length;
      } else if (i[0] === -1) {
        let value = i[1];
        let start = this.ace.current.editor.session.doc.indexToPosition(st);
        let end = this.ace.current.editor.session.doc.indexToPosition(st);
        allMarkers.push({
          startRow: start.row,
          startCol: start.column,
          endRow: end.row,
          endColumn: end.column,
          className: styles.deleted_marker,
          type: "background",
          text: value,
        });
      } else {
        st = st + i[1].length;
      }
    }
    this.context.setCorrections(allMarkers);
    // SearchContext.setCorrections(allMarkers);
    this.setState({ track: allMarkers, text: newValue });
    this.enableMarkers();
    //  console.log(allMarkers);
    return true;
    // var curMarkers = this.updateExistingMarker(event, this.state.track);
    // if (event.action === "remove") {
    //     var styleClass = styles.deleted_marker;
    //     let allText = this.state.text
    // } else {
    //     styleClass = styles.added_marker;
    //     let allText = newValue
    // }
    // var curMarker = { startRow: event.start.row, startCol: event.start.column, endRow: event.end.row, endCol: event.end.column, className: styleClass, type: 'text' }
    // // Ignoring the tracking of insertion of new line
    // if (event.action === "insert" && event.lines.length === 2 && event.lines.join("") === "") {
    //     curMarker = null
    // }
    // if (curMarker) {
    //     curMarkers.push(curMarker)
    // }
    // curMarkers = this.mergeMarkers(curMarkers)
    // this.setState({ track: [...curMarkers], text: allText })
    // this.enableMarkers()
    // return true;
  }

  mergeMarkers(curMarkers) {
    // console.log(curMarkers);
    var addedMarkers = curMarkers.filter(
      (a) => a.className.indexOf("Ace_added_marker") === 0
    );
    var deletedMarkers = curMarkers.filter(
      (a) => a.className.indexOf("Ace_deleted_marker") === 0
    );
    addedMarkers = addedMarkers.sort(
      (a, b) => a.startRow - b.startRow || a.startCol - b.startCol
    );
    var mergedAddedMarkers = [];
    if (addedMarkers.length > 0) {
      mergedAddedMarkers = [addedMarkers[0]];
      for (var i = 1; i < addedMarkers.length; i++) {
        var lastMerged = {
          ...mergedAddedMarkers[mergedAddedMarkers.length - 1],
        };
        var curMarker = addedMarkers[i];
        if (
          lastMerged.startRow === curMarker.startRow &&
          lastMerged.endRow === curMarker.endRow &&
          lastMerged.endCol === curMarker.startCol
        ) {
          lastMerged.endCol = curMarker.endCol;
          mergedAddedMarkers[mergedAddedMarkers.length - 1] = lastMerged;
        } else {
          mergedAddedMarkers.push(curMarker);
        }
      }
    }
    deletedMarkers = deletedMarkers.sort(
      (a, b) => a.startRow - b.startRow || a.startCol - b.startCol
    );
    var mergedDeletedMarkers = [];
    if (deletedMarkers.length > 0) {
      mergedDeletedMarkers = [deletedMarkers[0]];
      for (let i = 1; i < deletedMarkers.length; i++) {
        let lastMerged = {
          ...mergedDeletedMarkers[mergedDeletedMarkers.length - 1],
        };
        let curMarker = deletedMarkers[i];
        if (
          lastMerged.startRow === curMarker.startRow &&
          lastMerged.endRow === curMarker.endRow &&
          lastMerged.endCol === curMarker.startCol
        ) {
          lastMerged.endCol = curMarker.endCol;
          mergedDeletedMarkers[mergedDeletedMarkers.length - 1] = lastMerged;
        } else {
          mergedDeletedMarkers.push(curMarker);
        }
      }
    }
    var allMarkers = mergedAddedMarkers.concat(mergedDeletedMarkers);
    for (var a of allMarkers) {
      a.text = this.ace.current.editor.session.getTextRange({
        start: { row: a.startRow, column: a.startCol },
        end: { row: a.endRow, column: a.endCol },
      });
    }
    return allMarkers;
  }

  testEvent(event) {
    if (window.event.ctrlKey) {
      console.log("TEST");
    }

    window.ev = event;
  }

  processSelection(event) {
    this.hidePopover();
    var cursorPosition = event.editor.selection.getCursor();

    var selectedMarker = findRangeInMarkers(
      event.editor.getSelectionRange(),
      this.state.markers,
      cursorPosition
    );
    // console.log(selectedMarker)
    if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_citation") === 0
    ) {
      var bibText = selectedMarker.text;
      let name = this.allBibItems[bibText].name;
      var review = this.allBibItems[bibText].review;
      this.setState({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: bibText,
          body: <CitationPopup name={name} review={review} />,
          type: "citation",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_reference") === 0
    ) {
      let refText = selectedMarker.text;
      this.setState({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: refText,
          body: <ImagePopup name={refText} />,
          type: "Image",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_inline_equation") === 0
    ) {
      let eqn = selectedMarker.text;
      this.setState({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: "Math Eqn",
          body: <MathPopup equation={eqn} />,
          type: "matheqn",
        },
      });
    } else if (
      selectedMarker &&
      selectedMarker.className.indexOf("Ace_equation") === 0
    ) {
      let eqn = selectedMarker.text;
      this.setState({
        popup: {
          x: event.x,
          y: event.y,
          show: true,
          header: "Equation",
          body: <MathPopup equation={eqn} />,
          type: "equation",
        },
      });
    } else {
      if (event.editor.getSelectedText().length > 3) {
        this.context.setPdfSearchText(event.editor.getSelectedText());
      }
    }
  }

  setSelection(range) {
    // {start:{row:10, column:10}, end:{row: 10, column: 15}}
    this.ace.current.editor.session.selection.setSelectionRange(range);
    this.ace.current.editor.scrollToLine(range.start.row, true);
  }

  async saveTex() {
    var result = await saveTexApi(this.state.text, this.state.track);
    if (result.data) {
      this.props.toast({
        title: "Saving Tex.",
        description: "Save successfull.",
        status: "success",
        duration: 1000,
      });
    }
  }

  AutoCorrect = () => {
    return this.props.toast({ title: "Feature yet to be Implemented" });
  };

  /* Ace editor key Bindings */
  textToBold = () => {
    var selectedrange = this.ace.current.editor.getSelectionRange();
    let selectedtext = this.ace.current.editor.getSelectedText();

    this.ace.current.editor.session.replace(
      selectedrange,
      "\\textbf{" + selectedtext + "}"
    );
  };
  textToItalics = () => {
    var selectedrange = this.ace.current.editor.getSelectionRange();
    let selectedtext = this.ace.current.editor.getSelectedText();

    this.ace.current.editor.session.replace(
      selectedrange,
      "\\textit{" + selectedtext + "}"
    );
  };
  toLowerCase = () => {
    var selectedrange = this.ace.current.editor.getSelectionRange();
    let selectedtext = this.ace.current.editor.getSelectedText();
    // console.log(selectedtext);
    this.ace.current.editor.session.replace(
      selectedrange,
      selectedtext.toLowerCase()
    );
  };
  noBreak = () => {
    var selectedrange = this.ace.current.editor.getSelectionRange();
    let selectedtext = this.ace.current.editor.getSelectedText();
    this.ace.current.editor.session.replace(
      selectedrange,
      `\\nobreak{${selectedtext}}`
    );
  };
  hBox = () => {
    var selectedrange = this.ace.current.editor.getSelectionRange();
    let selectedtext = this.ace.current.editor.getSelectedText();
    this.ace.current.editor.session.replace(
      selectedrange,
      `\\hbox{${selectedtext}}`
    );
  };
  looseness = () => {
    var cursorPosition = this.ace.current.editor.getCursorPosition();
    this.ace.current.editor.session.insert(cursorPosition, "\\looseness-1");
  };

  vfill = () => {
    var cursorPosition = this.ace.current.editor.getCursorPosition();
    this.ace.current.editor.session.insert(cursorPosition, "\\vfill\\eject");
  };
  unSkip = () => {
    var cursorPosition = this.ace.current.editor.getCursorPosition();
    this.ace.current.editor.session.insert(cursorPosition, "\\unskip\\break");
  };

  updateSize() {
    // Need to find much better way to handle this setTimeout
    setTimeout(
      function () {
        this.ace.current.editor.resize(true);
        this.ace.current.editor.renderer.updateFull(true);
      }.bind(this),
      1000
    );
  }

  insertMath(text) {
    this.ace.current.editor.session.insert(
      this.ace.current.editor.getCursorPosition(),
      text
    );
  }

  render() {
    return (
      <span>
        <HStack
          backgroundColor="#F26722"
          justifyContent="space-between"
          h="48px"
        >
          <ButtonGroup spacing="1">
            {this.props.leftController ? (
              <IconButton
                onClick={() => {
                  this.props.toggleLeftPanel();
                }}
                size="lg"
                colorScheme="#F26722"
                aria-label="Close Left Panel"
                icon={<MenuClose />}
                _hover={{ bg: "#E05E1D" }}
              />
            ) : (
              <IconButton
                onClick={() => {
                  this.props.toggleLeftPanel();
                }}
                size="lg"
                colorScheme="#F26722"
                aria-label="Open Left Panel"
                icon={<MenuBar />}
                _hover={{ bg: "#E05E1D" }}
              />
            )}
            <IconButton
              onClick={this.saveTex.bind(this)}
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Save Tex File"
              icon={<SaveIcon />}
              _hover={{ bg: "#E05E1D" }}
            />
            <IconButton
              onClick={() => this.ace.current.editor.execCommand("find")}
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Search inside document"
              icon={<SearchIcon />}
              _hover={{ bg: "#E05E1D" }}
            />
            <IconButton
              onClick={() => this.ace.current.editor.execCommand("replace")}
              color="white"
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Replace inside document"
              icon={<ReplaceIcon />}
              _hover={{ bg: "#E05E1D" }}
            />
            <InsertMath insertMath={this.insertMath.bind(this)} />
            <IconButton
              onClick={this.textToBold.bind(this)}
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Text to Bold"
              icon={<BoldIcon />}
              _hover={{ bg: "#E05E1D" }}
            />
            <IconButton
              onClick={this.textToItalics.bind(this)}
              // fontSize="l"
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Text to Italic"
              icon={<ItalicIcon />}
              _hover={{ bg: "#E05E1D" }}
            />
            <IconButton
              onClick={this.AutoCorrect.bind(this)}
              size="lg"
              colorScheme="#f47c0d"
              aria-label="Autocorrect"
              icon={<Auto />}
              _hover={{ bg: "#E05E1D" }}
            />
          </ButtonGroup>
          {this.props.pdfviewer ? (
            <IconButton
              onClick={() => {
                this.props.togglePDF();
              }}
              size="lg"
              colorScheme="#f47c0d"
              aria-label="toogle-pdf"
              position="absolute"
              right="0"
              z-index="2"
              bg="#F26722"
              icon={<Resize1 />}
              _hover={{ bg: "#E05E1D" }}
            ></IconButton>
          ) : (
            <IconButton
              onClick={() => {
                this.props.togglePDF();
              }}
              size="lg"
              position="absolute"
              right="0"
              colorScheme="#f47c0d"
              aria-label="toogle-pdf"
              icon={<Resize2 />}
              _hover={{ bg: "#E05E1D" }}
            ></IconButton>
          )}
        </HStack>
        <PopOver state={this.state} onClick={() => this.hidePopover()} />
        <AceEditor
          markers={this.state.markers.concat(this.state.track)}
          mode="latex"
          theme="xcode"
          ref={this.ace}
          onChange={this.onChange.bind(this)}
          name="ace_editor"
          wrapEnabled={true}
          editorProps={{ $blockScrolling: true }}
          options={{ enableMultiselect: false }}
          fontSize={18}
          value={this.state.text}
          enableSnippets={true}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          width="100%"
          height="95%"
        />
      </span>
    );
  }
}

export default Ace;
