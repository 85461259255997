import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  ModalFooter,
  Checkbox,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import epl from "../eplstyles";
import { InfoOutlineIcon } from "@chakra-ui/icons";
// import { Tooltip } from "@chakra-ui/react";

const Styling = (props) => {
  const [stylesList, setStyleList] = useState([]);
  const [tooltip, setTooltip] = useState({ id: "" });
  // const [submitted, setSubmitted] = useState(false);

  const updateSelectedStyles = function (checked, data) {
    if (checked === true) {
      setStyleList([...stylesList, data]);
    } else {
      let filtered = stylesList.filter(function (el) {
        return el.name !== data.name;
      });
      setStyleList(filtered);
    }
  };

  const checkAll = (checked) => {
    if (checked) {
      Object.keys(epl).map((i) => {
        return setStyleList((prev) => [...prev, i]);
      });
    } else {
      setStyleList([]);
    }
  };

  const handleClick = () => {
    props.eplStyles(stylesList);
    setStyleList([]);
    // props.submitAction(true);
  };

  return (
    <Modal size={"xl"} onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          EPL
          <Spacer />
          <Checkbox
            onChange={(e) => {
              checkAll(e.target.checked);
            }}
            style={{ marginTop: "10px" }}
          >
            Select All
          </Checkbox>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2} spacing={4}>
            {Object.keys(epl).map((i) => (
              <>
                <Checkbox
                  className="checkbox"
                  isChecked={stylesList.indexOf(i) > -1}
                  onChange={(e) => updateSelectedStyles(e.target.checked, i)}
                  key={epl[i].name}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      gap: "4px",
                      display: "flex",
                      alignItems: "center",
                      width: "230px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{epl[i].name}</div>
                    <div
                      className="infoicon"
                      onMouseEnter={() => setTooltip({ id: i })}
                      onMouseLeave={() => setTooltip({ id: "" })}
                    >
                      <InfoOutlineIcon />
                    </div>
                    {tooltip.id === i ? (
                      <div
                        style={{
                          position: "absolute",
                          height: "auto",
                          width: "200px",
                          background: "rgba(14,13 , 13, 0.64)",
                          // background: "#faf9f9",
                          backdropFilter: "blur(4px)",
                          right: "-180px",
                          top: "25px",
                          zIndex: "1000",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {epl[i].description}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Checkbox>
              </>
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleClick(props);
              props.onClose();
            }}
            mx={2}
            colorScheme="blue"
          >
            Submit
          </Button>
          <Button onClick={props.onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    eplStyles: (data) => dispatch({ type: "Submit", payload: data }),
    // submitAction: (res) => dispatch({ type: "onChange", payload: res }),
  };
};

export default connect(null, mapDispatchToProps)(Styling);
