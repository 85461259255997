import React, { useEffect, useState } from "react";
import styles from "./ClientCarousel.module.css";
// import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
// import { cleanup } from "@testing-library/react";
// import { renderMathInDocument } from "mathlive";

const ClientCarousel = ({ isMobile, isTablet }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [paused, setpaused] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        handleslideadd();
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const logo = [];

  for (let i = 1; i <= 22; i++) {
    let icon = `cmp${i}.png`;
    logo.push(icon);
  }

  const handleslideadd = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % logo.length);
  };

  const handleslidesub = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + logo.length) % logo.length);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const touchDistance = touchEndX - touchStartX;
    const minSwipeDistance = 50; // Minimum distance (in pixels) to trigger a swipe

    if (Math.abs(touchDistance) >= minSwipeDistance) {
      if (touchDistance > 0) {
        handleslidesub(); // Swipe right
      } else {
        handleslideadd(); // Swipe left
      }
    }

    // Reset touch state
    setTouchStartX(0);
    setTouchEndX(0);
  };
  // console.log(logo);
  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <div className={styles.heading}>
          <p>We are Proud to Serve our Clients</p>
        </div>
        {isTablet ? (
          <></>
        ) : (
          <>
            <button>
              <div
                className={`${styles.npbtn} ${styles.btn1pos}`}
                onClick={handleslidesub}
              >
                {/* <VscChevronLeft fontSize="2rem" /> */}
                <img
                  alt="leftarrowicon"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Redesignpics/leftarrow.svg"
                  }
                />
              </div>
            </button>
            <button>
              <div
                className={`${styles.npbtn} ${styles.btn2pos}`}
                onClick={handleslideadd}
              >
                {/*<VscChevronRight fontSize="2rem" /> */}
                <img
                  alt="rightarrowicon"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Redesignpics/Right arrow.svg"
                  }
                />
              </div>
            </button>
          </>
        )}
        <div className={styles.carousel_out}>
          <div
            className={styles.slider_outer}
            onMouseEnter={() => setpaused(true)}
            onMouseLeave={() => setpaused(false)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* Render the original slides */}
            {logo.map((l, i) => {
              return (
                <div
                  className={styles.logoouter}
                  key={i}
                  style={{
                    transform: `translateX(-${currentSlide * 115}%)`,
                  }}
                >
                  <img
                    alt="our-clients"
                    src={process.env.PUBLIC_URL + `/images/Redesignpics/${l}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCarousel;
