import React from "react";
import styles from "./UniqueFeaturesnew.module.css";

const UniqueFeaturenew = ({ isTablet }) => {
  let data = [
    {
      text: "One click to view updated math",
      para: "No need to compile every time to view the display math after a change is made in the code. Just click to select the corrected code and the updated math would be visible in the same edit window immediately.",
    },
    {
      text: "Tracking of changes",
      para: "The editing pane tracks all the changes the authors make in the document. With track change on, as seen in Microsoft Word, all who are involved in the process can keep track of all the changes made to the document.",
    },
    {
      text: "Interactive Communication with Stakeholders",
      para: " Efficient built-in features for effective communication between authors, publishers and typesetters.",
    },
    {
      text: "Automated validation of queries",
      para: "With respect to publishing requirements, the system automatically validates all your query responses and alerts if anything is not attended; thus avoiding multiple interactions. The PDF, LaTeX, and queries are all interlinked, so one click brings up all the relevant content for you to view.",
    },
    {
      text: "Easy Viewing for Cross References",
      para: "As part of editing if any cross reference like figure, table , references etc needs to be checked, a click on the cross reference would display the element then and there. No need of any navigation.",
    },
  ];
  return (
    <div className={styles.advantageouter}>
      <div className={styles.content}>
        {isTablet ? (
          <></>
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/Redesignpics/2.svg"}
            alt="number_two_img"
          />
        )}
        {isTablet ? (
          <>
            <div className={styles.maincontent}>
              <div className={styles.advantage_list}>
                <div className={styles.list}>
                  <ul>
                    {data.map((i, index) => {
                      return (
                        <div className={styles.unique_list} key={index}>
                          <li>{i.text}</li>
                          <p>{i.para}</p>
                          {index === 1 && (
                            <div className={styles.advantageimg}>
                              <img
                                alt="advantageimg"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Redesignpics/unique.webp"
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.maincontent}>
            <div className={styles.advantage_list}>
              <div className={styles.list}>
                <ul>
                  {data.map((i, index) => {
                    return (
                      <div className={styles.unique_list} key={index}>
                        <li>{i.text}</li>
                        <p>{i.para}</p>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={styles.advantageimg}>
              <img
                alt="advantageimg"
                src={
                  process.env.PUBLIC_URL + "/images/Redesignpics/unique.webp"
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UniqueFeaturenew;
