import React, { useState } from "react";
import styles from "./RightBar.module.css";
import { getProofPdfApi } from "../api";

function RightBar(props) {
  const [active, setActive] = useState("");

  useState(() => {
    setActive("corrections");
  }, []);

  const getProofPdf = async () => {
    var result = await getProofPdfApi();
    if (result.data) {
      props.control((prevState) => ({
        ...prevState,
        view: "proofPdf",
        proofPdfData: "data:application/pdf;base64," + result.data.pdf,
      }));
    }
    setActive("proofpdf");
  };

  const corrections = () => {
    props.control((prevState) => ({
      ...prevState,
      view: "pdf",
    }));
    setActive("corrections");
  };

  const error = () => {
    props.control((prevState) => ({
      ...prevState,
      view: "error",
    }));
    setActive("error");
  };

  const raw = () => {
    props.control((prevState) => ({
      ...prevState,
      view: "raw",
    }));
    setActive("raw");
  };
  const xml = () => {
    props.control((prevState) => ({
      ...prevState,
      view: "xml",
    }));
    setActive("xml");
  };
  return (
    <div className={styles.right_bar_container}>
      <div
        onClick={() => corrections()}
        className={`${
          active === "corrections" ? styles.current : styles.disabled
        } ${styles.right_bar_menu}`}
      >
        Correction
      </div>
      <div
        onClick={getProofPdf}
        className={`${
          active === "proofpdf" ? styles.current : styles.disabled
        } ${styles.right_bar_menu}`}
      >
        Proof
      </div>
      <div
        onClick={() => xml()}
        className={`${active === "xml" ? styles.current : styles.disabled} ${
          styles.right_bar_menu
        }`}
      >
        XML
      </div>
      <div
        onClick={() => error()}
        className={`${active === "error" ? styles.current : styles.disabled} ${
          styles.right_bar_menu
        }`}
      >
        Error Log
      </div>
      <div
        onClick={() => raw()}
        className={`${active === "raw" ? styles.current : styles.disabled} ${
          styles.right_bar_menu
        }`}
      >
        Raw Log
      </div>
    </div>
  );
}

export default RightBar;
