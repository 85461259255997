import React from "react";
// import styles from "./Query.module.css";
import { Box, VStack, Text, Heading, Button, Divider } from "@chakra-ui/react";
import { connect } from "react-redux";

const Query = (props) => {
  const enterAnswer = (a, i) => {
    // if (a.index === i) {
    let lasttext = props.ace.current.editor.session.getLine(a.startrow);
    let textatpos = lasttext.substring(a.endCol, a.endCol - 2);
    if (a.ans === undefined && textatpos !== "") {
      if (!a.endcol) {
        props.ace.current.editor.session.insert(
          { row: a.endrow, column: a.endCol },
          "{"
        );
        props.ace.current.editor.session.insert(
          { row: a.endrow, column: a.endCol + 1 },
          "}"
        );
        props.ace.current.editor.session.selection.moveTo(
          a.endrow,
          a.endCol + 1
        );
        props.ace.current.editor.focus();
      }
    } else {
      props.ace.current.editor.session.selection.setRange({
        start: { row: a.endrow, column: a.endCol },
        end: { row: a.endrow, column: a.endCol },
      });
      props.ace.current.editor.session.selection.moveTo(a.endrow, a.endCol - 1);
      props.ace.current.editor.focus();
      // }
      props.ace.current.editor.scrollToLine(a.endrow);
    }
  };

  const scrollToPos = (a) => {
    if (a.ans) {
      props.ace.current.editor.scrollToLine(a.startrow);
    }
  };

  return (
    <VStack overflow="scroll" height="80%">
      {props.query && props.query.length > 0 ? (
        props.query.map((a, i) => {
          return (
            <div key={i} style={{ width: "100%" }}>
              {
                <Box
                  key={i}
                  p="4"
                  cursor={a.ans ? "pointer" : ""}
                  onClick={() => scrollToPos(a)}
                  backgroundColor="transparent"
                  width="100%"
                  wordBreak="break"
                  mt="8px"
                  ml="5px"
                  textAlign="left"
                >
                  <Heading
                    fontSize="16px"
                    padding="8px 0"
                    fontFamily="Roboto, sans-serif"
                  >
                    {a.index === i ? `Q: ${a.qns}` : <></>}
                  </Heading>
                  {a.ans ? (
                    <Text>{a.index === i ? `A: ${a.ans}` : <></>}</Text>
                  ) : (
                    <Button onClick={() => enterAnswer(a, i)}>
                      Click to add Answer
                    </Button>
                  )}
                </Box>
              }
              <Divider />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </VStack>
  );
};

const mapStateToProps = (state, ownProps) => ({
  query: state.queries.queries,
  ace: ownProps.ace,
});

export default connect(mapStateToProps, null)(Query);
