import React from "react";
import styles from "./KeyAspectsnew.module.css";

const KeyAspectsnew = (props) => {
  let tabletview = props.isTablet;
  // console.log(tabletview);
  let data = [
    {
      text: "Solution for LaTeX authors",
    },
    {
      text: "Seamless Integration with publisher's workflow",
    },
    {
      text: "Online proofing/submission",
    },
    {
      text: "Management of  queries/comments",
    },
    {
      text: "PDF revision with track changes",
    },
    {
      text: "Full text XML generation",
    },
    {
      text: "Attractive pricing plans",
    },
    {
      text: "Real time layout adjustments",
    },
  ];
  return (
    <div className={styles.advantageouter}>
      <div className={styles.content}>
        {tabletview ? (
          <></>
        ) : (
          <img
            alt="FeatureNumber4"
            src={process.env.PUBLIC_URL + "/images/Redesignpics/4.svg"}
          />
        )}

        <div className={styles.maincontent}>
          <div className={styles.advantageimg}>
            <img
              alt="KeyAspects of EditLatex"
              src={
                process.env.PUBLIC_URL +
                "/images/Redesignpics/KeyAspectsimg.webp"
              }
            />
          </div>
          <div className={styles.advantage_list}>
            <div className={styles.list}>
              <ul>
                {data.map((i, index) => {
                  return <li key={index}>{i.text}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyAspectsnew;
