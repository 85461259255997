import React from "react";
import { Box, Button, Text, VStack, Heading, Divider } from "@chakra-ui/react";
import styles from "./Ace.module.css";
import { connect } from "react-redux";

const Corrections = (props) => {
  // console.log(props.ace.current);
  // console.log(track);
  // var track = useMemo(() => {
  //   return props.corrections;
  // }, [props.corrections]);
  // const SearchText = useContext(SearchContext);
  // console.log(SearchText.corrections);
  // try {
  //   var track = props.ace.current.state.track;
  // } catch {
  //   var track = [];
  // }

  let moveToPosition = function (a) {
    if (!a.endCol) {
      props.ace.current.editor.gotoLine(a.startRow + 1, a.startCol);
    } else {
      props.ace.current.editor.session.selection.setRange({
        start: { row: a.startRow, column: a.startCol },
        end: { row: a.endRow, column: a.endCol },
      });
    }
    props.ace.current.editor.scrollToLine(a.startRow, true);
  };

  const acceptChanges = (a, i) => {
    if (a.className.indexOf("Ace_deleted_marker") === 0) {
      let text = document.querySelector(".text");
      console.log(text);
      text.classList.add(styles.accepted_text);
    }
    console.log(i);
  };

  return (
    <VStack overflow="scroll" height="80%">
      {props.corrections && props.corrections.length > 0 ? (
        props.corrections.map((a, i) => {
          // console.log(a.index);
          // console.log(i);
          var mode =
            a.className.indexOf("Ace_added_marker") === 0 ? "Added" : "Deleted";
          var bgColor = mode === "Added" ? "green" : "red";
          return (
            <div key={i} style={{ width: "100%" }}>
              <Box
                key={i}
                p="4"
                cursor="pointer"
                onClick={() => moveToPosition(a)}
                backgroundColor="transparent"
                width="100%"
                wordBreak="break"
                mt="8px"
                ml="5px"
              >
                <Text fontSize="16px" paddingBottom="12px">
                  {mode}
                  {/* {a.time} */}
                </Text>
                <Heading
                  className="text"
                  fontSize="18px"
                  color={bgColor}
                  paddingBottom="12px"
                  fontFamily="Roboto, sans-serif"
                >
                  {a.text.replace(" ", "<space>")}
                </Heading>
                <Text fontSize="12px">By : </Text>
                <Button
                  height="20px"
                  backgroundColor="green"
                  color="#fff"
                  margin="4px 2px"
                  onClick={() => acceptChanges(a, i)}
                >
                  Accept
                </Button>
                <Button
                  height="20px"
                  backgroundColor="red"
                  color="#fff"
                  margin="4px 2px"
                >
                  Reject
                </Button>
              </Box>
              <Divider style={{ borderColor: "#C1BFBD" }} />
            </div>
          );
        })
      ) : (
        <p style={{ padding: "24px", margin: 0 }}>No Corrections</p>
      )}
    </VStack>
  );
};

const mapStateToProps = (state, ownProps) => ({
  corrections: state.correction.corrections,
  ace: ownProps.ace,
});

export default connect(mapStateToProps, null)(Corrections);
