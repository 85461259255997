import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Button,
  Tooltip,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { MathEditor } from "./MathLive";
// import { TbMath } from "react-icons/tb";
import { useEffect, useState } from "react";
import { MathEqn } from "./icons";

export function InsertMath(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mathVal, setMathVal] = useState("");
  const [eqnValue, setEqnValue] = useState("Inline");

  useEffect(() => {
    setMathVal("");
  }, [isOpen]);

  const setEqnType = (value) => {
    setEqnValue(value);
  };
  // console.log(eqnValue);

  return (
    <>
      <Tooltip label="Enter Math">
        <IconButton
          onClick={onOpen}
          fontSize="1xl"
          color="white"
          size="lg"
          colorScheme="#f47c0d"
          aria-label="Insert math equation"
          icon={<MathEqn />}
          _hover={{ bg: "#E05E1D" }}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        useInert={false}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Insert Math Equation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel>Equation Type :</FormLabel>
            <RadioGroup
              onChange={setEqnType}
              name="eqnselector"
              value={eqnValue}
              paddingBottom="10px"
            >
              <Stack direction="row">
                <Radio value="Inline">Inline</Radio>
                <Radio value="Equation">Equation</Radio>
                <Radio value="Equationarray">Equation Array</Radio>
              </Stack>
            </RadioGroup>

            <MathEditor
              value={mathVal}
              onChange={setMathVal}
              options={{ virtualKeyboardMode: "manual" }}
              containerStyle={{ border: "solid 1px" }}
            />
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              onClick={() => {
                if (eqnValue === "Inline") {
                  props.insertMath("$" + mathVal + "$");
                } else if (eqnValue === "Equation") {
                  props.insertMath(
                    "\\begin{equation}\n" + mathVal + "\n\\end{equation}"
                  );
                } else if (eqnValue === "Equationarray") {
                  props.insertMath(
                    "\\begin{eqnarray}\n" + mathVal + "\n\\end{eqnarray}"
                  );
                }
                onClose();
              }}
              colorScheme="blue"
            >
              Insert Math
            </Button>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
