// function getAllBibitems(texText) {
//   var allLines = texText.split("\n");
//   var allBibItems = {};
//   for (var i = 0; i < allLines.length; i++) {
//     var line = allLines[i].trim();
//     if (line.indexOf("\\bibitem") === 0) {
//       try {
//         var nameLine = allLines[i + 1].trim();
//         let laststring = nameLine.substring(nameLine.lastIndexOf(" ") + 1);
//         if (!laststring.includes("}")) {
//           let twolinename = `${allLines[i + 1]} ${allLines[i + 2]}`.trim();
//           let twolinenamereview = allLines[i + 3].trim();
//           let key = new RegExp("{(.*?)}").exec(line)[1];
//           let bibname = new RegExp("{(.*?)}").exec(twolinename)[1];
//           let review = new RegExp("{(.*)}")
//             .exec(twolinenamereview)[1]
//             .replace(/}/g, " ")
//             .replace(/{/g, " ");
//           allBibItems[key.trim()] = { name: bibname, review: review };

import { all } from "axios";

//           // console.log(allBibItems);
//         }
//         var reviewLine = allLines[i + 2].trim();
//         var key = new RegExp("{(.*?)}").exec(line)[1];
//         var bibname = new RegExp("{(.*?)}").exec(nameLine)[1];
//         var review = new RegExp("{(.*)}")
//           .exec(reviewLine)[1]
//           .replace(/}/g, " ")
//           .replace(/{/g, " ");
//         i = i + 3;
//         allBibItems[key.trim()] = { name: bibname, review: review };
//       } catch (Exc) {
//         // console.log(Exc)
//       }
//     }
//   }
//   return allBibItems;
// }

function getAllBibitems(texText) {
  let allbibitems = {};
  let bibRegex = new RegExp(
    /\s?\\bibitem\s?\{(.*)?\}\n?\s?\\Name\s?\{(.+\n?.+)\}\n?\s?\\[Rt]\w+\{(.+)\}\.?$/gm
  );
  let match;
  while ((match = bibRegex.exec(texText)) !== null) {
    let allbib = match;
    let key = allbib[1];
    let bibname = allbib[2];
    let review = allbib[3].replace(/{/g, " ").replace(/}/g, " ");
    allbibitems[key.trim()] = { name: bibname, review: review };
  }
  if (allbibitems != null) {
    return allbibitems;
  }
}

function getAllEquations(texText) {
  let allEqns = {};
  let eqnregex = new RegExp(
    /\\begin\s*(\{(equation|align|eqnarray|array|case|gather|matrix|multline)\s*\})(.*?)(\\end\1)/gms
  );
  let res;
  while ((res = eqnregex.exec(texText)) !== null) {
    let eqn = res;
    if (eqn[0].includes("label")) {
      let label = eqn[0].match(/\\label{(.*?)}/g);
      console.log(label.length);
      if (label.length === 1) {
        label = label[0].replace(/\\label\{/, "").replace(/\}/, "");
        allEqns[label] = { eqn: eqn };
      } else if (label.length >= 2) {
        console.log(label);
        label.forEach((e) => {
          e = e.replace(/\\label\{/, "").replace(/\}/, "");
          allEqns[e] = { eqn: eqn };
        });
      }
    }
  }
  return allEqns;
}

function getAllTables(texText) {
  let allTables = {};
  let tableregex = new RegExp(/\\begin\{table\*?\}(.*?)\\end\{table\*?\}/gims);
  let res;
  while ((res = tableregex.exec(texText)) !== null) {
    let tab = res;
    if (tab[0].includes("label")) {
      let label = tab[0].match(/\\label{(.*?)}/);
      allTables[label[1]] = { tab: tab };
    }
  }
  // console.log(allTables);
  return allTables;
}

function findRangeInMarkers(selectionRange, markers, cursor) {
  var selectionMarker = {
    startRow: selectionRange.start.row,
    startCol: selectionRange.start.column,
    endRow: selectionRange.end.row,
    endCol: selectionRange.end.column,
  };
  // console.log(markers);
  for (var marker of markers) {
    if (window.event.ctrlKey) {
      if (marker.startRow < marker.endRow) {
        if (
          cursor.row >= marker.startRow &&
          cursor.row <= marker.endRow &&
          marker.startCol <= cursor.column &&
          marker.endCol >= cursor.column
        ) {
          return marker;
        }
      } else if (
        marker.startRow === cursor.row &&
        marker.startCol <= cursor.column &&
        marker.endCol >= cursor.column
      ) {
        return marker;
      }
    } else if (
      marker.startRow === selectionMarker.startRow &&
      marker.startCol === selectionMarker.startCol &&
      marker.endRow === selectionMarker.endRow &&
      marker.endCol === selectionMarker.endCol
    ) {
      return marker;
    }
  }
  return false;
}

function regexFinderTool(lines, allTools) {
  // ace.current.editor.session.getDocument().$lines
  // var lines = allLines.split("\r\n");
  // console.log(lines)
  var all_res = [];
  for (let i = 0; i < lines.length; i++) {
    let text = lines[i];
    for (let tool of allTools) {
      let regexes = tool.regex;
      var matches = text.match(regexes[0]);
      if (regexes.length > 1 && matches != null) {
        const counts = {};
        for (const num of matches) {
          counts[num] = counts[num] ? counts[num] + 1 : 2;
        }
        const total = {};
        for (let match of matches) {
          total[match] = total[match] ? total[match] : counts[match];
          counts[match] = counts[match] - 1;
          let res = { description: tool["text"] };
          let sub_match = match.match(regexes[1]);
          if (sub_match != null) {
            res["line"] = i;
            res["message"] = sub_match;
            res["text"] = sub_match;
            res["nth"] = total[match] - counts[match];
            res["col"] = text
              .split(sub_match, res["nth"])
              .join(sub_match).length;
            all_res.push(res);
          }
        }
        matches = null;
      }
      if (matches != null) {
        var nth = 0;
        const counts = {};
        for (const num of matches) {
          counts[num] = counts[num] ? counts[num] + 1 : 2;
        }
        const total = {};
        for (let match of matches) {
          total[match] = total[match] ? total[match] : counts[match];
          counts[match] = counts[match] - 1;
          let res = { description: tool["text"] };
          res["line"] = i;
          res["message"] = match;
          res["text"] = match;
          res["nth"] = total[match] - counts[match];
          res["col"] = text.split(match, res["nth"]).join(match).length;
          all_res.push(res);
        }
      }
    }
  }
  return all_res;
}

function downloadFile(content, fileName, type) {
  let blob = new Blob([content], { type });
  let url = window.URL.createObjectURL(blob);
  downloadURI(url, fileName);
  window.URL.revokeObjectURL(url);
  return;
}

function downloadURI(uri, name) {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.click();
}

export {
  getAllBibitems,
  getAllEquations,
  getAllTables,
  findRangeInMarkers,
  regexFinderTool,
  downloadFile,
  downloadURI,
};
