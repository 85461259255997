import React, { useRef, useEffect, useState, useContext } from "react";
import SplitPaneContext from "./Contexts/SplitPaneContext";
import Styles from "./App.module.css";
import { Provider, connect } from "react-redux";
import store from "./store/Store";

export const Divider = (props) => {
  const { onMouseDown, dragging } = useContext(SplitPaneContext);

  return (
    <div
      {...props}
      onMouseDown={onMouseDown}
      // ontouchStart={onTouchStart}
      className={dragging ? Styles.draggingresizer : Styles.resizer}
    />
  );
};

export const SplitPaneLeft = (props) => {
  const topRef = useRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientWidth) {
      setClientWidth(topRef.current.clientWidth);
      return;
    }
    topRef.current.style.minWidth = clientWidth + "px";
    topRef.current.style.maxWidth = clientWidth + "px";
  }, [clientWidth, setClientWidth, topRef]);

  return <div {...props} className={Styles.split_pane_left} ref={topRef}></div>;
};

const SplitPane = ({ children, ...props }) => {
  const [clientWidth, setClientWidth] = useState();
  const [dragging, setDragging] = useState(false);
  const XDividerPos = useRef(null);
  const parentWidth = useRef(null);

  let splithalf;

  useEffect(() => {
    splithalf = parentWidth.current.getBoundingClientRect().width / 2;
    props.pdffull === "true" ? setClientWidth(0.1) : setClientWidth(splithalf);
  }, [props.pdffull]);

  useEffect(() => {
    splithalf = parentWidth.current.getBoundingClientRect().width / 2;
    if (props.leftcontroller === "true") {
      let parent = props.parentwidth - 230;
      console.log(parent);
      if (props.pdfviewer === "true") {
        setClientWidth(parent / 2);
      }
      if (props.pdfviewer === "false") {
        setClientWidth(parent);
      }
    } else {
      props.pdfviewer === "true"
        ? setClientWidth(splithalf)
        : setClientWidth(splithalf * 2);
    }
  }, [props.pdfviewer, props.leftcontroller, props.parentwidth]);

  const onMouseDown = (e) => {
    setDragging(true);
    XDividerPos.current = e.clientX;
  };

  // const onTouchStart = (e) => {
  //   e.preventDefault();

  //   setDragging(true);
  //   XDividerPos.current = e.clientX;
  // };

  const onMouseUp = (e) => {
    setDragging(false);
    XDividerPos.current = null;
  };

  const onMouseMove = (e) => {
    if (!XDividerPos.current) {
      return;
    }

    const newclientwidth = clientWidth + e.clientX - XDividerPos.current;

    setClientWidth(newclientwidth);

    XDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
    // document.addEventListener("touchend", onMouseUp);
    // document.addEventListener("touchmove", onMouseMove);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      // document.addEventListener("touchend", onMouseUp);
      // document.addEventListener("touchmove", onMouseMove);
    };
  });

  return (
    <div {...props} ref={parentWidth}>
      <SplitPaneContext.Provider
        value={{
          clientWidth,
          setClientWidth,
          onMouseDown,
          dragging,
          splithalf,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

// export const Divider = (props) => {
//   // const { onMouseDown, dragging } = useContext(SplitPaneContext);
//   return (
//     <div
//       {...props}
//       onMouseDown={props.onMouseDown}
//       // ontouchStart={onTouchStart}
//       className={props.dragging ? Styles.draggingresizer : Styles.resizer}
//     />
//   );
// };

// export const SplitPaneLeft = (props) => {
//   const topRef = useRef();

//   useEffect(() => {
//     if (!props.clientwidth) {
//       props.setclientwidth(topRef.current.clientWidth);
//       return;
//     }
//     topRef.current.style.minWidth = props.clientwidth + "px";
//     topRef.current.style.maxWidth = props.clientwidth + "px";
//   }, [props.clientwidth, props.setclientwidth, topRef]);
//   console.log(props);
//   return <div className={Styles.split_pane_left} ref={topRef}></div>;
// };

// const SplitPane = (props) => {
//   // const [clientWidth, setClientWidth] = useState();
//   // const [dragging, setDragging] = useState(false);
//   const XDividerPos = useRef(null);
//   const parentWidth = useRef(null);

//   let splithalf;

//   useEffect(() => {
//     splithalf = parentWidth.current.getBoundingClientRect().width / 2;
//     props.pdffull === "true"
//       ? props.setclientwidth(0.1)
//       : props.setclientwidth(splithalf);
//   }, [props.pdffull]);

//   useEffect(() => {
//     splithalf = parentWidth.current.getBoundingClientRect().width / 2;
//     if (props.leftcontroller === "true") {
//       let parent = props.parentwidth - 230;
//       console.log(parent);
//       if (props.pdfviewer === "true") {
//         props.setclientwidth(parent / 2);
//       }
//       if (props.pdfviewer === "false") {
//         props.setclientwidth(parent);
//       }
//     } else {
//       props.pdfviewer === "true"
//         ? props.setclientwidth(splithalf)
//         : props.setclientwidth(splithalf * 2);
//     }
//   }, [props.pdfviewer, props.leftcontroller, props.parentwidth]);

//   const onMouseDown = (e) => {
//     props.setdragging(true);
//     XDividerPos.current = e.clientX;
//   };

//   const onMouseUp = (e) => {
//     props.setdragging(false);
//     XDividerPos.current = null;
//   };

//   const onMouseMove = (e) => {
//     if (!XDividerPos.current) {
//       return;
//     }

//     const newclientwidth = props.clientwidth + e.clientX - XDividerPos.current;

//     props.setclientwidth(newclientwidth);

//     XDividerPos.current = e.clientX;
//   };

//   useEffect(() => {
//     document.addEventListener("mouseup", onMouseUp);
//     document.addEventListener("mousemove", onMouseMove);
//     // document.addEventListener("touchend", onMouseUp);
//     // document.addEventListener("touchmove", onMouseMove);

//     return () => {
//       document.removeEventListener("mouseup", onMouseUp);
//       document.removeEventListener("mousemove", onMouseMove);
//       // document.addEventListener("touchend", onMouseUp);
//       // document.addEventListener("touchmove", onMouseMove);
//     };
//   });

//   <props.children {...props} ref={parentWidth} />
//   console.log(props);
//   return (
//     // <div {...props} ref={parentWidth}>
//     //   {/* <SplitPaneLeft
//     //     clientwidth={props.clientwidth}
//     //     setclientwidth={props.setclientwidth}
//     //   />
//     //   <Divider onMouseDown={onMouseDown} dragging={props.dragging} /> */}
//     //   {props.children}
//     // </div>
//   );
// };

// const mapStateToProps = (state, ownProps) => ({
//   clientwidth: state.clientwidth.clientwidth,
//   dragging: state.dragging.dragging,
// });

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setclientwidth: (data) => dispatch({ type: "clientwidth", payload: data }),
//     setdragging: (data) => dispatch({ type: "dragging", payload: data }),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(SplitPane);

export default SplitPane;
