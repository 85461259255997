import { combineReducers } from "redux";

const initialState = {};

function correctionReducer(state = initialState, action) {
  // Handle different actions and update the state accordingly
  if (action.type == "EDIT") {
    return { ...state, corrections: action.payload };
  }
  // Add more cases for your specific actions
  else {
    return state;
  }
}

function queriesReducer(state = initialState, action) {
  if (action.type == "QueryEdit") {
    return { ...state, queries: action.payload };
  } else {
    return state;
  }
}

function UploadTexFile(state = initialState, action) {
  if (action.type == "Uploadtex") {
    return { ...state, tex: action.payload };
  } else {
    return state;
  }
}

function latexSearchText(state = initialState, action) {
  if (action.type == "latexSearch") {
    return { ...state, latexSearch: action.payload };
  } else {
    return state;
  }
}

function pdfSearchText(state = initialState, action) {
  if (action.type == "pdfSearch") {
    return { ...state, pdfSearch: action.payload };
  } else {
    return state;
  }
}

function toolsSelection(state = initialState, action) {
  if (action.type == "toolSearch") {
    return { ...state, toolSearch: action.payload };
  } else {
    return state;
  }
}

function setClientWidth(state = initialState, action) {
  if (action.type == "clientwidth") {
    return { ...state, clientwidth: action.payload };
  } else {
    return state;
  }
}

function setDragging(state = initialState, action) {
  if (action.type == "dragging") {
    return { ...state, dragging: action.payload };
  } else {
    return state;
  }
}

function selectEplStyles(state = initialState, action) {
  if (action.type == "Submit") {
    // console.log("hi");
    return { ...state, function: action.payload };
  } else {
    return state;
  }
}

function compileTex(state = initialState, action) {
  if (action.type == "compile") {
    return { ...state, compile: action.payload };
  } else {
    return state;
  }
}

function trackCompile(state = initialState, action) {
  if (action.type == "trackcompile") {
    return { ...state, trackcompile: action.payload };
  } else {
    return state;
  }
}
// function enableOnChange(state = initialState, action) {
//   if(action.type == "onChange"){
//     console.log("reached reducer");
//     console.log(action.payload);
//     return {...state, currentstate: action.payload }
//   }else{
//     return state;
//   }
// }
const rootReducer = combineReducers({
  correction: correctionReducer, // reducer for correction update's
  queries: queriesReducer, // reducer for query update's
  uploadTex: UploadTexFile, // reducer for texfile for compilation
  latexsearch: latexSearchText, // reducer for searching text in editor
  pdfsearch: pdfSearchText, // reducer for searching text in PDF
  toolsearch: toolsSelection, // reducer for searching the tools output in editor.
  clientwidth: setClientWidth,
  dragging: setDragging,
  eplStyles: selectEplStyles, //reducer for selecting epl styles.
  // submitAction: enableOnChange,
  compileTex: compileTex,
  trackCompile: trackCompile,
});

export default rootReducer;
