import axios from "axios";

async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    resolved.error = e;
  }

  return resolved;
}

export async function downloadTexApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/tex", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function saveTexApi(tex, track) {
  var data = { tex: tex, track: JSON.stringify(track) };
  return await resolve(
    axios
      .post(window.global.server_url + "api/v1/file/tex", data, {
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
  );
}

export async function compileNormalTexApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/compile/normal", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function compileTrackTexApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/compile/track", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getNormalPdfApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/pdf/normal", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getProofPdfApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/pdf/proof", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getTrackPdfApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/pdf/track", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getPrintPdfApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/pdf/print", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getWebPdfApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/pdf/web", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getXmlApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/xml", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function getReferenceImageApi(image_name) {
  return await resolve(
    axios(window.global.server_url + "api/v1/file/image/" + image_name, {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function deleteTokenApi() {
  return await resolve(
    axios
      .delete(window.global.server_url + "api/v1/api-token-auth/revoke", {
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
  );
}

export async function getTokenApi(fileId, email) {
  var data = { password: fileId, email: email };
  return await resolve(
    axios
      .post(window.global.server_url + "api/v1/api-token-auth/", data, {
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
  );
}

export async function validateTokenApi() {
  return await resolve(
    axios(window.global.server_url + "api/v1/api-token-auth/validate", {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    }).then((res) => res.data)
  );
}

export async function uploadFile(file) {
  var formData = new FormData();
  formData.append("file", file);
  return await resolve(
    axios
      .post(window.global.server_url + "api/v1/file/upload", formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
  );
}

export async function submitFileApi() {
  return await resolve(
    axios
      .post(
        window.global.server_url + "api/v1/file/submit",
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
  );
}

export async function tryitOutApi(formdata) {
  return await resolve(
    axios
      .post(window.global.server_url + "api/v1/user/tryit", formdata, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
  );
}

export async function citationref(data) {
  const apiUrl = "http://anystyle.io/";
  return await resolve(
    axios
      .post(apiUrl, data, { headers: { "Content-Type": "text/plain" } })
      .then((res) => res.data)
  );
}
