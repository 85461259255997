import React from "react";
import styles from "./Loading.module.css";
import { Spinner } from '@chakra-ui/react'

const Loading = (props) => {
  console.log(props);
  return (
    <div className={props.Loading ? styles.loading : styles.loading}>
      <Spinner thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl' />
    </div>
  );
};

export default Loading;
