import React, { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { getReferenceImageApi } from "../api";

function ImagePopup(props) {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const data = await getReferenceImageApi(props.name);
      if (data.data) {
        setData("data:image/jpeg;base64," + data.data.image);
      } else {
        // setData("data:image/jpeg;base64," + data.data.image);
        props.toast({
          title: "Error",
          description: "No image found",
          duration: 1500,
        });
      }
    })();
  }, []);

  return (
    <VStack align="start">
      <Image src={data} boxSize="250px" objectFit="fit" />
    </VStack>
  );
}

export default ImagePopup;
