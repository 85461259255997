import {
  Box,
  Center,
  Flex,
  FormControl,
  Img,
  Input,
  Text,
  Checkbox,
  Button,
  Hide,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import styles from "./LoginDetails.module.css";
import { useNavigate } from "react-router-dom";
import { tryitOutApi } from "../../api";
import { useToast } from "@chakra-ui/react";

const LoginDetails = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loginDetails, setLoginDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    org: "",
    demo_mode: "",
  });

  const [validation, setValidation] = useState({
    fname: true,
    lname: true,
    email: true,
    mobile: true,
    org: true,
    demo_mode: true,
  });

  const [errors, setErrors] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    org: "",
    demo_mode: "",
  });

  var emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

  const validateForm = () => {
    let valid = true;
    const newvalid = { ...validation };
    const newerror = { ...errors };

    if (loginDetails.fname.trim() === "") {
      newvalid.fname = false;
      newerror.fname = "Name field cannot be empty";
      valid = false;
    } else {
      newvalid.fname = true;
      newerror.fname = "";
    }

    if (loginDetails.email === "") {
      newvalid.email = false;
      newerror.email = "Email Field cannot be empty";
      valid = false;
    } else if (!emailRegex.test(loginDetails.email)) {
      newvalid.email = false;
      newerror.email = "Enter a valid email ID";
      valid = false;
    } else {
      newvalid.email = true;
      newerror.email = "";
    }

    if (loginDetails.mobile === "") {
      newvalid.mobile = false;
      newerror.mobile = "Enter a mobile Number";
      valid = false;
    } else {
      newvalid.mobile = true;
      newerror.mobile = "";
    }

    if (loginDetails.org === "") {
      newvalid.org = false;
      newerror.org = "Please enter organization name";
      valid = false;
    } else {
      newvalid.org = true;
      newerror.org = "";
    }

    if (loginDetails.demo_mode === "") {
      newvalid.demo_mode = false;
      newerror.demo_mode = "Please select a mode for demo";
      valid = false;
    } else {
      newvalid.demo_mode = true;
      newerror.demo_mode = "";
    }

    setValidation(newvalid);
    setErrors(newerror);

    return valid;
  };

  const submit = async () => {
    if (validateForm()) {
      toast({
        title: "Setting up",
        description: "Setting up the initial files.",
        status: "info",
        duration: null,
        icon: (
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        ),
      });
      let result = await tryitOutApi(loginDetails);
      toast.closeAll();
      if (result.data.token) {
        localStorage.setItem("token", result.data.token);
        navigate("/#" + result.data.file);
      } else {
        toast({
          title: "User already registered.",
          description:
            "If you have already registered kindly check you email for demo link.",
          status: "error",
          duration: null,
        });
      }
    }
  };
  return (
    <Box
      backgroundImage={
        process.env.PUBLIC_URL + "/images/Redesignpics/background1.svg"
      }
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      w="100%"
      h="100vh"
    >
      <Center>
        <Box
          w={["80%", "80%", "80%", "70%"]}
          height="90vh"
          borderRadius="32px"
          mt="2%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Hide below="lg">
            <Box
              w="50%"
              height="90vh"
              backgroundImage={
                process.env.PUBLIC_URL +
                "/images/Redesignpics/transformaLogo2.svg"
              }
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              borderRadius="32px 0 0 32px"
              color="#fff"
              position="relative"
            >
              <Img
                src={
                  process.env.PUBLIC_URL +
                  "/images/Redesignpics/TransformaLogo.png"
                }
                width="187px"
                style={{
                  position: "absolute",
                  left: "calc(50% - 93.5px)",
                  top: "calc(50% - 15px)",
                }}
              />
            </Box>
          </Hide>
          <Box
            w={["95%", "95%", "80%", "50%"]}
            h={["65vh", "60vh", "60vh", "90vh"]}
            backgroundColor="#fff"
            borderRadius={["32px", "32px", "32px", "0 32px 32px 0"]}
            className={styles.form1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent={["center", "center", "right"]}
              w="100%"
            >
              <Img
                w={["40%", "40%", "40%"]}
                mt="32px"
                mr="32px"
                src={
                  process.env.PUBLIC_URL +
                  "/images/Redesignpics/EditLatexlogo.png"
                }
              ></Img>
            </Box>
            <FormControl
              w="80%"
              m="28px"
              display="flex"
              flexDirection="column"
              gap={5}
              overflow="auto"
              alignItems={["center", "center", "center", "start"]}
              isRequired
            >
              <Input
                variant="flushed"
                placeholder="First Name"
                type="text"
                focusBorderColor="#222"
                _hover={{
                  borderBottom: "1px",
                  borderColor: "#222",
                }}
                onChange={(e) =>
                  setLoginDetails({
                    fname: e.target.value,
                  })
                }
              />
              {!validation.fname && (
                <Text color="red" fontSize="10px" mt="-5px">
                  {errors.fname}
                </Text>
              )}
              <Input
                variant="flushed"
                placeholder="Last Name"
                type="text"
                focusBorderColor="#222"
                _hover={{
                  borderBottom: "1px",
                  borderColor: "#222",
                }}
                onChange={(e) =>
                  setLoginDetails({ ...loginDetails, lname: e.target.value })
                }
              />
              {!validation.lname && (
                <Text color="red" fontSize="10px" mt="-5px">
                  {errors.lname}
                </Text>
              )}
              <Input
                variant="flushed"
                placeholder="Email ID"
                type="email"
                focusBorderColor="#222"
                _hover={{
                  borderBottom: "1px",
                  borderColor: "#222",
                }}
                onChange={(e) =>
                  setLoginDetails({ ...loginDetails, email: e.target.value })
                }
              />
              {!validation.email && (
                <Text color="red" fontSize="10px" mt="-5px">
                  {errors.email}
                </Text>
              )}

              <Input
                variant="flushed"
                placeholder="Mobile Number"
                type="number"
                focusBorderColor="#222"
                _hover={{
                  borderBottom: "1px",
                  borderColor: "#222",
                }}
                onChange={(e) =>
                  setLoginDetails({
                    ...loginDetails,
                    mobile: e.target.value,
                  })
                }
              />
              {!validation.mobile && (
                <Text color="red" fontSize="10px" mt="-5px">
                  {errors.mobile}
                </Text>
              )}
              <Input
                variant="flushed"
                placeholder="Organization Name"
                type="text"
                focusBorderColor="#222"
                _hover={{
                  borderBottom: "1px",
                  borderColor: "#222",
                }}
                onChange={(e) =>
                  setLoginDetails({
                    ...loginDetails,
                    org: e.target.value,
                  })
                }
              />
              {!validation.org && (
                <Text color="red" fontSize="10px" mt="-5px">
                  {errors.org}
                </Text>
              )}
              <Flex gap={2} alignItems="center" mt="15px">
                <Text fontSize={{ base: "12px", sm: "12px", md: "15px" }}>
                  Demo mode:
                </Text>
                <Checkbox
                  colorScheme="blackAlpha"
                  name="demoMode"
                  isChecked={loginDetails.demo_mode === "single"}
                  onChange={(e) =>
                    setLoginDetails({
                      ...loginDetails,
                      demo_mode: e.target.checked ? "single" : "",
                    })
                  }
                >
                  <Text fontSize={{ base: "12px", sm: "12px", md: "13px" }}>
                    Single Column
                  </Text>
                </Checkbox>
                <Checkbox
                  colorScheme="blackAlpha"
                  name="demoMode"
                  isChecked={loginDetails.demo_mode === "double"}
                  onChange={(e) =>
                    setLoginDetails({
                      ...loginDetails,
                      demo_mode: e.target.checked ? "double" : "",
                    })
                  }
                >
                  <Text fontSize={{ base: "12px", sm: "12px", md: "13px" }}>
                    Double Column
                  </Text>
                </Checkbox>
                {!validation.demo_mode && (
                  <Text color="red" fontSize="12px">
                    {errors.demo_mode}
                  </Text>
                )}
              </Flex>
              <Button
                w="40%"
                mt="25px"
                minH="52px"
                style={{ background: "#000", color: "#fff" }}
                type="submit"
                onClick={() => submit()}
              >
                Submit
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Center>

      <Box h="40px" display="flex" alignItems="center" justifyContent="center">
        <Text fontSize={{ base: "12px", sm: "12px", md: "15px" }}>
          <span style={{ color: "#f26722" }}>Transforma Pvt Ltd - </span>
          editlatex © 2023
        </Text>
      </Box>
    </Box>
  );
};

export default LoginDetails;
