import React from "react";
import styles from "./About2.module.css";
import { Heading } from "@chakra-ui/react";

const About2 = () => {
  return (
    <div className={styles.about2_outer}>
      <div className={styles.content1}>
        <div className={styles.content1Heading}>
          <Heading size="xl" fontFamily="Georgia" fontWeight={400}>
            About
          </Heading>
        </div>
        <div className={styles.contentpara}>
          <p>
            EditLaTeX is an advanced editing and typesetting platform
            specifically designed for LaTeX documents. With EditLaTeX, authors
            and researchers can easily collaborate, edit, and format their LaTeX
            manuscripts with precision and efficiency. This powerful tool
            streamlines the editing process, allowing for seamless collaboration
            between authors and editors. EditLaTeX provides a user-friendly
            interface that enables authors to make real-time changes,
            incorporate feedback, and ensure the accuracy and consistency of
            their LaTeX documents. Whether it is for academic papers, scientific
            publications, or technical documents, EditLaTeX offers a
            comprehensive solution for enhancing the quality and presentation of
            LaTeX manuscripts.
          </p>
        </div>
      </div>
      <div className={styles.contenthighlight}>
        <p>
          EditLaTeX is an online platform that allows users to write, edit, and
          format research papers in LaTeX.
        </p>
        <img
          src={process.env.PUBLIC_URL + "/images/Redesignpics/colon.svg"}
          alt="apostrophe"
        />
        <div className={styles.imgbig}>
          <img
            src={process.env.PUBLIC_URL + "/images/Redesignpics/aboutimg1.webp"}
            alt="shows companies involvement in digital content"
          />
        </div>
        <div className={styles.imgsmall}>
          <img
            src={
              process.env.PUBLIC_URL + "/images/Redesignpics/aboutsmall.webp"
            }
            alt="shows companies involvement in digital content"
          />
        </div>
      </div>
    </div>
  );
};

export default About2;
