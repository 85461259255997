import React from "react";
import styles from "../pagecomponents/MainPoster.module.css";

const MainPoster = () => {
  return (
    <div className={styles.poster_container}>
      {/* Desktop view */}
      <div className={styles.home_poster}>
        <img
          src={process.env.PUBLIC_URL + "/images/Redesignpics/webBanner.webp"}
          alt="with heading Editlatex reinvent the way you think, para built in intelligence for precise output. Making publishing perfected and optimised for specific requirements."
        />
      </div>

      {/* For tablet view */}
      <div className={styles.Tablet_poster}>
        <img
          src={process.env.PUBLIC_URL + "/images/Redesignpics/tabBanner.webp"}
          alt="with heading Editlatex reinvent the way you think, para built in intelligence for precise output. Making publishing perfected and optimised for specific requirements."
        />
      </div>

      {/*mobile view  */}
      <div className={styles.mobile_poster}>
        <img
          src={process.env.PUBLIC_URL + "/images/Redesignpics/mobBanner.webp"}
          alt="with heading Editlatex reinvent the way you think, para built in intelligence for precise output. Making publishing perfected and optimised for specific requirements."
        />
      </div>
      {/* for small mobiles */}
      <div className={styles.sm_mobile}>
        <img
          src={process.env.PUBLIC_URL + "/images/Redesignpics/mobBanner.webp"}
          alt="with heading Editlatex reinvent the way you think, para built in intelligence for precise output. Making publishing perfected and optimised for specific requirements."
        />
      </div>
    </div>
  );
};

export default MainPoster;
