import React, { useState } from "react";
import styles from "../FAQ.module.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const Questions = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(-1);

  const changeOpenIndex = (i) => {
    if (openIndex === i) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(i);
    }
  };

  // const handleClick = (e) => {
  //   let outer = document.querySelectorAll(`.${styles.answer_outer}`);
  //   // setIsOpen((ele) => (ele == e ? e : -1));
  //   outer.forEach((el) => {
  //     console.log(el.classList);
  //     if (parseInt(el.id) === e) {
  //       if (el.classList.contains(styles.show)) {
  //         el.classList.remove(styles.show);
  //         el.classList.add(styles.hide);
  //       } else {
  //         el.classList.remove(styles.hide);
  //         el.classList.add(styles.show);
  //       }
  //     } else {
  //       // setIsOpen(open);
  //       el.classList.remove(styles.show);
  //       el.classList.add(styles.hide);
  //     }
  //   });
  // };

  return (
    <div className={styles.question_section}>
      {data.map((item, index) => {
        var dropIcon =
          index === openIndex ? <AiOutlineMinus /> : <AiOutlinePlus />;
        return (
          <div key={index} className={styles.whole_outer}>
            <div
              className={styles.question_outer}
              onClick={() => changeOpenIndex(index)}
            >
              <p
                className={
                  index === openIndex ? styles.highlight : styles.normal
                }
              >
                {item.question}
              </p>
              {dropIcon}
            </div>
            <div
              id={index}
              className={`${styles.answer_outer} ${
                index === openIndex ? styles.show : styles.hide
              }`}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Questions;
