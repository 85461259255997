import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Checkbox,
    Stack,
} from '@chakra-ui/react'
import { useState } from 'react'


function CeTools(props) {
    var selectedTools = []
    const allCeTools = [{ "name": "d_dash", "text": "Double Dash (--) Check.", "regex": [/\$(.*?)\$/g, /--/g] },
    { "name": "space_command", "text": "Space after commands.", "regex": [/\\(bibitem|name|review|section) /ig]},
    { "name": "dollar_units", "text": "Units inside the $.", "regex": [/\$(.*?)\$/g, /Kg|Cm/ig]},
    { "name": "thousand_sep", "text": "Thousand Seperator.", "regex": [/ +(\d{4,5}) +/g]},
    { "name": "hyphenated", "text": "Hyphenated Words.", "regex": [/\w+(-)\w+/ig]},
    { "name": "time_unit", "text": "Time Units.", "regex": [/((year|day|month|second|week)s*)/ig]},
    { "name": "si_unit", "text": "SI Units.", "regex": [/ +(K|T|V|l|h|m|g|bp|kb|km|ms|ng|lb|min|cm|ksi|meV|eV|nm|mm|W\/cm|mw\/cm|Hz|J\/cm|Å|Pa|kg|MHz|torr|arcsec|keV|kW|ns|mA|mW|kV|v\/m|min|mg|yr|mol|nmol|J m|H cm|keV|Å|rpm|g\/l|ml|Bq\/kg|kBq|MeV|kA|mt|GPa|GHz|dB|MPa|nA|Da|kDa|kGy|g\/kg|mM|mV|ft|ppm|mil|hp|in.|pH|µM|µg|µm|µl|µL|Gyr|Myr|M ha|mg\/dm3|m|kg\/ha|t\/ha|dS\/m|dpi|mmol|mL\/min|mL|µg\/ml|nt|wt|M|N|rpm|s|nC|pA|kHz|mTorr|J|mmHg|mo|hr|wk|yr) +/ig]},
    { "name": "latin_words", "text": "Latin Words. ", "regex": [/ +(a priori|ad hoc|in vitro|in vivo|per se|post hoc|vice versa) +/ig]}]

    const updateSelectedTools = function(checked, data) {
        if (checked) {
            selectedTools.push(data)
        } else {
            selectedTools = selectedTools.filter(function(el) { return el.name != data.name; }); 
        }
    }

    return (
        <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Copy-Editing Tools</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Stack>
                    {allCeTools.map((i, j) => <Checkbox onChange={(e) => updateSelectedTools(e.target.checked, i)} key={i.name}>{i.text}</Checkbox>)}
                </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {props.processTools(selectedTools); props.onClose();}} mx={2} colorScheme='blue'>Submit</Button>
                    <Button onClick={props.onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
export default CeTools;