import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import store from "./store/Store.js";
import LoginDetails from "./pages/pagecomponents/LoginDetails";
const root = ReactDOM.createRoot(document.getElementById("root"));
// https://medium.com/@deepankaracharyya/deploying-a-django-app-with-apache-mod-wsgi-windows-e9712dc07bba
window.global = {
  server_url: "https://" + window.location.hostname + ":8443",
  // server_url: "https://editlatex.transforma.in:8443/",
};
var base_path = "";
if (process.env.REACT_APP_STAGE === "webpage") {
  base_path = "";
}
root.render(
  <Provider store={store}>
    <ChakraProvider toastOptions={{ defaultOptions: { position: "top" } }}>
      {/* <React.StrictMode> */}
      <Router basename={base_path}>
        <Routes>
          <Route path="/home/" element={<Home />} />
          <Route path="/home/:page" element={<Home />} />
          <Route path="/" element={<App />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/tryit" element={<LoginDetails />} />
        </Routes>
      </Router>

      {/* </React.StrictMode> */}
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
