// import { citationref } from "./api";
// import { Toast, useToast } from "@chakra-ui/react";

const epl = {
  removecommentedtext: {
    name: "Remove commented text",
    description: "Removes the commented text that are specified by % symbol.",
    action: (data, filename) => {
      data = data.replace(/(?<!\\)%[^\n]*/gms, "");
      return data;
    },
  },
  removeunwantedtext: {
    name: "Remove \\textcolor{black}",
    description:
      "Removes the text \\textcolor{black} throughtout the body of the document",
    action: (data, filename) => {
      data = data.replace(/\\textcolor{black}/gm, "");
      return data;
    },
  },
  preamble: {
    name: "Insert preamble before title",
    description: "Inserts the default custom tags before the \\title tag.",
    action: (data, filename) => {
      let preamble = `
\\documentclass[doublecol,final]{epl2}

\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{multirow}
\\usepackage{mathrsfs}

%\\usepackage{showframe}
%\\usepackage[allfiguresdraft]{draftfigure}

\\usepackage{xcolor}

\\usepackage[bookmarks=false]{hyperref}

\\hypersetup{
colorlinks=false,
pdfborder={0 0 0},
}

\\makeatletter
\\setlength\\arraycolsep{2pt}
\\makeatother

\\vol{xx}
\\issue{xx}
\\year{2023}
\\copyyear{2023}
\\firstpage{1}
\\doi{10.1209/0295-5075/xx/xxxxx}
\\pgid{xxxxx}
\\received{}
\\acceptedinfinalform{}
\\paperpub{Xxxx xxxx}
\\onlinepub{xx Xxxx xxxx}
`;
      let docbegin = new RegExp(/(.*)(?=\\title)/gms);
      let begintext = docbegin.exec(data);
      if (begintext) {
        console.log(begintext);
        data = data.replace(begintext[0], `${preamble}\n`);
      }
      return data;
    },
  },
  insertbegindocument: {
    name: "Insert \\begin{document}",
    description:
      "Inserts the \\begin{document} tag above the \\maketitle tag where ever it is placed within the tex.",
    action: (data, filename) => {
      let begindoc = new RegExp(/\\begin{document}/g);
      begindoc = begindoc.exec(data);

      if (begindoc == null) {
        data = data.replace(
          /\\maketitle/g,
          `\\begin{document} \n\n\\maketitle`
        );
        return data;
      } else {
        data = data.replace(begindoc, "");
      }
      data = data.replace(/\\maketitle/g, `\\begin{document} \n\n\\maketitle`);
      return data;
    },
  },
  titleLowerCase: {
    name: "Captilize \\title tag",
    description:
      "Captilizes the title tag by lowercasing the content except for the first character and the content with in the $ sign.",
    action: (data, filename, toast) => {
      let index = 1;
      let eqn = [];
      let replaced;
      let titletag = new RegExp(
        /\\title\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
      );
      titletag = titletag.exec(data);
      let eqnreg = /$([^$]*)$/gms;
      let eqnintitle;
      let modified;
      if (titletag) {
        if (titletag[0].includes("$")) {
          while ((eqnintitle = eqnreg.exec(titletag[1])) !== null) {
            eqn.push({ key: `eqn${index}`, value: eqnintitle[1] });
            titletag[1] = titletag[1].replace(eqnintitle[0], `eqn${index++}`);
            replaced = titletag[1];
          }
          replaced = replaced.charAt(0) + replaced.substring(1).toLowerCase();

          for (let i in eqn) {
            replaced = replaced.replace(eqn[i].key, `$${eqn[i].value}$`);
            modified = replaced;
          }
        } else {
          titletag[1] =
            titletag[1].charAt(0) + titletag[1].substring(1).toLowerCase();

          modified = titletag[1];
        }
        data = data.replace(titletag[0], `\\title{${modified}}\n`);
        return data;
      } else {
        toast({
          title: "Error",
          description: "Title tag not found",
          duration: 1500,
        });
        return data;
      }
    },
  },
  mathinsertion: {
    name: "Insert \\mth in title tag",
    description: "Replaces the the $ sign in the title tag with \\mth.",
    action: (data, filename, toast) => {
      if (data) {
        if (/\\title\{(.*)\}(?=\\)?/gm.test(data)) {
          data = data.replace(/\\title\{(.*)\}(?=\\)?/gm, (i) => {
            if (i) {
              i = i.replace(/(\$.*?\$)/g, (K) => {
                return "\\mth{" + K.substring(1, K.length - 1) + "}";
              });
              return i;
            }
          });
          return data;
        } else {
          toast({
            title: "Error",
            description: "Title tag not found",
            duration: 1500,
          });
          return data;
        }
      }
    },
  },
  shortTitle: {
    name: "Captilize \\Shorttitle",
    description:
      "Captilizes \\shorttitle tag by lowercasing the content excluding the first character.",
    action: (data, filename, toast) => {
      let shorttitle = new RegExp(/\\shorttitle\{(.*)\}(?=\\)?/gm);

      shorttitle = shorttitle.exec(data);

      if (shorttitle) {
        data = data.replace(/\\shorttitle\{(.*)\}(?=\\)?/gm, (i, j) => {
          let g1 = j.toLowerCase();
          g1 = g1.replace(/^(\w)/g, (k, l) => k.toUpperCase());
          return "\\shorttitle{" + g1 + "}\n";
        });
      } else {
        let title = new RegExp(
          /\\title\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
        );
        title = title.exec(data);
        if (title) {
          data = data.replace(title[0], (i) => {
            let g1 = title[1].toLowerCase();
            g1 = g1.replace(/^(\w)/g, (k, l) => k.toUpperCase());
            return `${i} \n\n\\shorttitle{${g1}}\n`;
          });
        } else {
          toast({
            title: "Error",
            description: "Insert \\title tag first",
            duration: 1500,
          });
          return data;
        }
      }
      return data;
    },
  },
  authornaming: {
    name: "\\author tag formatting ('.' & '\\inst')",
    description:
      "Provides space after '.' in author initials, and removes the space before \\inst tag.",
    action: (data, filename, toast) => {
      let listofauthor = [];
      let res;
      let authors = new RegExp(
        /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
      );

      let shorttitle = new RegExp(
        /\\shorttitle\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
      );

      shorttitle = shorttitle.exec(data);
      if (shorttitle) {
        while ((res = authors.exec(data)) != null) {
          listofauthor.push(res[0]);
          data = data.replace(res[0], "");
          authors = new RegExp(
            /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
          );
        }
      }

      if (shorttitle) {
        if (data)
          data = data.replace(
            shorttitle[0],
            `${shorttitle[0]}\n\n${listofauthor.join("\n")}\n`
          );
      } else {
        toast({
          title: "Error",
          description: "Please insert \\shorttitle",
          duration: 1500,
        });
        return data;
      }

      listofauthor.forEach((i) => {
        // add spacing after dot
        let dotspace = i.replaceAll(/(?<=\.)\w+/gms, (i) => {
          i = i.replace(i, " " + i);
          return i;
        });
        data = data.replace(i, dotspace);
        // removing space before /inst{}
        let inst = i.matchAll(/\s\\inst\{[^}]*\}/gm);
        for (const i of inst) {
          let correctinst = i[0].replaceAll(" ", "");
          data = data.replace(i[0], correctinst);
        }
      });
      return data;
    },
  },
  formatand: {
    name: "add \\and in \\author",
    description: "The 'and' in the author tag will be replaced by \\and.",
    action: (data, filename, toast) => {
      const names = new RegExp(
        /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
      );
      if (
        /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm.test(
          data
        )
      ) {
        let res;
        while ((res = names.exec(data)) !== null) {
          let corr = res[res.length - 1];
          let and = corr.replace(/(?<=\W),?\s?\\?and(?=\W)/gm, " \\and ");
          data = data.replace(corr, and);
        }
        return data;
      } else {
        toast({
          title: "Error",
          description: "\\author tag not found.",
          duration: 1500,
        });
        return data;
      }
    },
  },
  shortAuthor: {
    name: "\\shortauthor 'and' & 'texit{et.al.}'",
    description:
      "If there are two authors separated by comma the comma is replaced by 'and', if there are more than three authors 'textit{et.al.}' replaces the remaining authors leaving the first one.",
    action: (data, filename, toast) => {
      let shortAuthor = new RegExp(
        /\\shortauthor\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
      );
      ///\\shortauthor\{(.*)\}$/gm
      let listofshortauthor = []; //array to store list of shortauthors
      let res;
      // executes when the tex file has shortauthor
      if (data) {
        if (data.includes("\\shortauthor")) {
          while ((res = shortAuthor.exec(data)) !== null) {
            // console.log(res);
            listofshortauthor.push(res[1]);
            data = data.replace(res[0], "");
            shortAuthor = new RegExp(
              /\\shortauthor\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
            );
          }
        }
        // executes when shortauthor tag is absent uses names from the author tag
        else {
          let authors = new RegExp(
            /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
          );
          if (
            /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm.test(
              data
            )
          ) {
            while ((res = authors.exec(data)) !== null) {
              if (res[2].includes("\\inst")) {
                res[2] = res[2].replaceAll(/\\inst\{[^}]*\}/g, "");
              }
              listofshortauthor.push(res[2]);
            }
          } else {
            toast({
              title: "Error",
              description: "\\author tag not found.",
              duration: 1500,
            });
            return data;
          }
        }

        //formats the author names inside the listofshortauthor array

        let shortauthor = listofshortauthor.map((i) => {
          let dotspace = i.replaceAll(/(?<=\.)\w+/gms, (i) => {
            i = i.replace(i, " " + i);
            return i;
          });
          i = i.replace(i, dotspace);
          // to format the shorttitle based on number of authors present
          let breakpoint = /,\s?(?=\w)|\\and/gm;
          let split = i.split(breakpoint);
          console.log(split);
          if (split.length === 2) {
            res = split.join(" and ");
            i = i.replace(i, res);
          } else if (split.length > 2) {
            res = `${split[0]} \\textit{et al.}`;
            i = i.replace(i, res);
          }
          i = i.replace(i, `\\shortauthor{${i}}`);
          return i;
        });

        let authors = new RegExp(
          /\\author(\[\w+\])?\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
        );
        let res1;
        let authorlist = [];
        while ((res1 = authors.exec(data)) !== null) {
          authorlist.push(res1[0]);
        }
        let lastauthor = authorlist[authorlist.length - 1];
        if (data)
          data = data.replace(
            lastauthor,
            `${lastauthor}\n\n${shortauthor.join("\n")}`
          );
        return data;
      }
    },
  },
  addinstitutetag: {
    name: "address/affiliation to \\inst{ }",
    description:
      "Modifies the address or affiliation tag into \\inst{} and wraps them within \\institute tag",
    action: (data, filename, toast) => {
      if (data)
        if (data.includes("\\institute")) {
          let institute = new RegExp(
            /\\institute\s*?(\[.+\])?\s*\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gms
          );
          institute = institute.exec(data);
          data = data.replace(
            institute[0],
            `\\institute{\n${institute[institute.length - 1]}\n}`
          );
          return data;
        } else if (/(\\address|\\affiliation|\\affili)/g.test(data)) {
          let institute = new RegExp(
            /(\\affiliation|\\address|\\affili)\s*?(\[.+\])?\s*\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
          );
          let res;
          let index = 1;
          let instvalue = [];
          if (
            /(\\affiliation|\\address|\\affili)\s*?(\[.+\])?\s*\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm.test(
              data
            )
          ) {
            while ((res = institute.exec(data)) !== null) {
              instvalue.push(res[res.length - 1]);
              data = data.replace(res[0], "");
              institute = new RegExp(
                /(\\affiliation|\\address|\\affili)\s*?(\[.+\])?\s*\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/
              );
            }

            let newinstitute = `\\institute{\n${instvalue.map((i) => {
              return `\\inst{${index++}} ${i}\n`;
            })}\n}`;

            let shortAuthor = new RegExp(
              /\\shortauthor\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gm
            );
            let res1;
            let shortauthorlist = [];
            while ((res1 = shortAuthor.exec(data)) !== null) {
              shortauthorlist.push(res1[0]);
            }
            let lastshortauthor = shortauthorlist[shortauthorlist.length - 1];

            data = data.replace(
              lastshortauthor,
              `${lastshortauthor}\n\n${newinstitute}`
            );
          }
          return data;
        } else {
          toast({
            title: "Error",
            description: "\\affiliation or \\Institute tags not found",
            duration: 1500,
          });
          return data;
        }
    },
  },
  footNote: {
    name: "Replace space before \\footnote",
    description: "whitespace before the \\footnote tag is removed.",
    action: (data, filename, toast) => {
      let footnote = new RegExp(/\.*?,*?;*?\s*?\\footnote\{([^}]*)\}/g);
      let res;
      if (data)
        if (/\.*?,*?;*?\s*?\\footnote\{([^}]*)\}/g.test(data)) {
          while ((res = footnote.exec(data)) !== null) {
            data = data.replace(res[0], `\\footnote{${res[1]}}.`);
          }
          return data;
        } else {
          toast({
            title: "Error",
            description: "\\FootNote tag not found.",
            duration: 1500,
          });
          return data;
        }
    },
  },
  percentageStyling: {
    name: "Remove space before \\%",
    description: "Removes one space before the \\% tag.",
    action: (data, filename, toast) => {
      if (data)
        if (/\d+\s+\\?%/g.test(data)) {
          data = data.replace(/\d+\s+\\?%/g, (i) => {
            i = i.replace(" ", "");
            return i;
          });
          return data;
        } else {
          toast({
            title: "Error",
            description: "% not found in file ",
            duration: 1500,
          });
          return data;
        }
    },
  },
  QuotesStyling: {
    name: "Replace '' / \"\" with `` '' ",
    description: "Replaces the single quotes with double quotes in the tex.",
    action: (data, filename) => {
      if (data)
        data = data.replace(/['"][A-Za-z-\\0-9]*['"]/gm, (i) => {
          return `\`\`${i.substring(1, i.length - 1)}''`;
        });
      return data;
    },
  },
  abstractStyling: {
    name: "Add ~ before \\abstract",
    description:
      "Adds ~ symbol before the start of content inside the \\abstract tag.",
    action: (data, filename, toast) => {
      if (data)
        if (data.includes("\\abstract")) {
          let abstract = new RegExp(
            /\\abstract\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gms
          );
          let res;
          while ((res = abstract.exec(data)) !== null) {
            if (res[0].includes("~")) {
              return data;
            } else {
              res[1] = res[1].replace("\n", "");
              data = data.replace(
                res[0],
                `\\abstract{~${res[res.length - 1]}}`
              );
            }
          }
          return data;
        } else if (data.includes("\\begin{abstract}")) {
          let abstract = new RegExp(/\\begin{abstract}(.*)\\end{abstract}/gms);
          let res = abstract.exec(data);
          data = data.replace(res[0], `\\abstract{~${res[1]}}`);
          return data;
        } else {
          toast({
            title: "Error",
            description: "\\abstract tag not found.",
            duration: 1500,
          });
          return data;
        }
      // return data;
    },
  },
  SIUnitStyling: {
    name: "Enclose SIUnit with \\un{ }",
    description: "The SIUnits within the tex are enclosed within \\un{} tag.",
    action: (data, filename) => {
      if (data)
        data = data.replace(
          /([^\w])(\d+\$*\s?~*\.*)(m\.s|kg\.m|s|A|K|mol|cd|C|V|Ω|T|Wb|J|W|Pa|Hz|GHz|rad|sr|N|J|W|Wb|C|V|F|Ω|S|Wb|T|H|eV|u|Lx|lm|lx|Bq|Gy|Sv|kat|dB|kg|mm|m)(?!\w)/gm,
          (i) => {
            console.log(i);
            let j = i.replace(
              /~*(m\.s|kg\.m|s|A|K|mol|cd|C|V|Ω|T|Wb|J|W|Pa|Hz|GHz|rad|sr|N|J|W|Wb|C|V|F|Ω|S|Wb|T|H|eV|u|Lx|lm|lx|Bq|Gy|Sv|kat|dB|kg|mm|m)/,
              (k) => {
                if (k.includes("~")) {
                  k = k.replace("~", "");
                }
                return `\\un{${k}}`;
              }
            );
            return j;
          }
        );
      return data;
    },
  },
  beginequation: {
    name: "\\begin equation",
    description:
      "Replaces the label tag contents if present with the filename followed by the eqn number, also changes the eqn ref within the tex to its respective label.",
    action: (data, filename) => {
      let equation = new RegExp(
        /\\begin\s*(\{(equation|align|eqnarray|array|case|gather|matrix|multline)\s*\})(.*?)(\\end\1)/gms
      );
      let eqnpair = [];
      let res;
      let index = 1;
      while ((res = equation.exec(data)) !== null) {
        if (res && res[0].includes("\\label")) {
          let label = [];
          // to replace //label before //end
          let replaced;
          if (
            /\\begin\s*(\{(equation|align|eqnarray|array|case|gather|matrix|multline)\s*\*?\}\s*\n*\\label{([^}]*)})/gms.test(
              res[0]
            )
          ) {
            //to store the label in array and replace it with ""
            let getlabel = res[0].replace(/(\\label\{([^}]*)\})/gms, (i) => {
              label.push(i);
              i = i.replace(i, "");
              return i;
            });
            replaced = getlabel.replace(
              /\\end\{(equation|align|eqnarray|array|case|gather|matrix|multline)\s*\*?\}/gms,
              (j) => {
                let ans = `${label[0]}\n${j}`;
                return ans;
              }
            );
            data = data.replace(res[0], replaced);
            let labelname = /(\\label\{([^}]*)})/gms.exec(replaced);
            eqnpair.push({ key: labelname, value: `${filename}eqn${index}` });
            data = data.replace(
              labelname[0],
              `\\label{${filename}eqn${index++}}`
            );
          } else {
            let newlabel = /(\\label\{([^}]*)})/gms.exec(res[0]);
            eqnpair.push({ key: newlabel, value: `${filename}eqn${index}` });
            let newlabel2 = newlabel[0].replace(
              newlabel[0],
              `\\label{${filename}eqn${index++}}`
            );
            data = data.replace(newlabel[0], newlabel2);
          }
        } else {
          let replaced;
          replaced = res[0].replace(
            /\\end\{(equation|align|eqnarray|array|case|gather|matrix|multline)\s*\*?\}/gms,
            (item) => {
              let ans = `\\label{${filename}eqn${index++}}\n${item}`;
              return ans;
            }
          );
          data = data.replace(res[0], replaced);
        }
      }
      let ref = new RegExp(
        /(Equation\.*~?\.?-?\s*|Eq\.*~?\.?-?\s*|Eqs\.*~?\.?-?\s*)?\s*\(?\\(ref|eqref)\{([^}]*)}\)?/gims
      );
      // if equation reference starts with only \ref
      let eqres;
      while ((eqres = ref.exec(data)) !== null) {
        if (eqres) {
          if (/^\s*\(?(\\(ref|eqref)({[^}]*)})\)?/gims.test(eqres[0])) {
            for (let n of eqnpair) {
              if (n.key[n.key.length - 1] === eqres[eqres.length - 1]) {
                data = data.replace(
                  eqres[0],
                  ` (\\${eqres[eqres.length - 2]}{${n.value}})`
                );
              }
            }
          }
        }
      }
      // If equation citation starts after '.' are at the beginning of the line.
      let capeqn =
        /((?<=\.|{)\s*Eq[uations]*\.*\s*~*-*|^\s*Eq[uations]*\.*\s*~*-*)\(?(\\(ref|eqref)\{([^}]*)})\)?/gim;
      let res1;
      while ((res1 = capeqn.exec(data)) !== null) {
        for (let n of eqnpair) {
          if (n.key[n.key.length - 1] === res1[res1.length - 1]) {
            data = data.replace(
              res1[0],
              `Equation~(\\${res1[res1.length - 2]}{${n.value}})`
            );
          }
        }
      }
      // if equation citation is inbetween the text
      let smaeqn =
        /((?<=\w)\s*\(*Eq[uations]*\.*\s*~*-*)\(?(\\(ref|eqref)?{([^}]*)})\)?\)*/gims;
      let res2;
      while ((res2 = smaeqn.exec(data)) !== null) {
        for (let n of eqnpair) {
          if (n.key[n.key.length - 1] === res2[res2.length - 1]) {
            data = data.replace(
              res2[0],
              ` eq.~(\\${res2[res2.length - 2]}{${n.value}})`
            );
          }
        }
      }
      return data;
    },
  },
  begintable: {
    name: "\\begintable",
    description:
      "Replaces the label tag contents if present with the filename followed by the eqn number, also changes the table ref within the tex to its respective label.",
    action: (data, filename) => {
      let begintable = new RegExp(
        /\\begin\{table\*?\}(.*?)\\end\{table\*?\}/gims
      );
      let res;
      let index = 1;
      let tabpair = [];
      while ((res = begintable.exec(data)) !== null) {
        if (res && res[0].includes("\\label")) {
          let label = /\\label{(.*?)}(?=\n*(\\|\{))/gims.exec(res[0]);
          if (label)
            tabpair.push({
              key1: label[label.length - 2],
              key2: index,
              value: `\\label{${filename}tab${index}}`,
            });
          let rename = label[0].replace(
            label[0],
            `\\label{${filename}tab${index++}}`
          );
          let newres = res[0].replace(label[0], rename);
          data = data.replace(res[0], newres);
        }
      }
      let ref = new RegExp(/\s*Table\s*\\ref\{([^}]*)\}/gims);
      let tabref;
      while ((tabref = ref.exec(data)) !== null) {
        console.log(tabref);
        if (tabref) {
          if (
            /((?<=\.)\s*Tab[le]*|^\s*Tab[le]*|^)\s*~*\n*\\ref\{([^}]*)}/gims.test(
              data
            )
          ) {
            for (let n of tabpair) {
              if (n.key1 === tabref[tabref.length - 1]) {
                data = data.replace(tabref[0], `Table~\\ref{${n.value}}`);
              }
            }
          } else {
            for (let n of tabpair) {
              if (n.key1 === tabref[tabref.length - 1]) {
                data = data.replace(tabref[0], `table~\\ref{${n.value}}`);
              }
            }
          }
        }
      }
      return data;
    },
  },
  beginfigure: {
    name: "\\beginfigure",
    description:
      "Replaces the label tag contents if present with the filename followed by the eqn number, also changes the figure ref within the tex to its respective label.",
    action: (data, filename) => {
      let beginfigure = new RegExp(
        /\\begin\{figure\*?\}(.*?)\\end\{figure\*?\}/gims
      );
      let res;
      let index = 1;
      let figpair = [];
      let newcaption;
      let newlabel;
      let rename;
      // let includegraphics;
      while ((res = beginfigure.exec(data)) !== null) {
        if (res && res[0].includes("\\caption")) {
          newcaption =
            /\\caption\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}/gims;
          newcaption = newcaption.exec(res[0]);
          // data = data.replace(newcaption[1], "");
        }
        // console.log(newcaption);
        if (res[0].includes("\\label")) {
          // let label = /(?<=caption\s*)\\label\{([^\}]*)\}/gims;
          let label = /\\label\{([^}]*)\}/gims;
          newlabel = label.exec(res[0]);
          figpair.push({
            key: newlabel[newlabel.length - 1],
            value: `${filename}fig${index}`,
          });
          newlabel = newlabel[0].replace(
            newlabel[0],
            `\\label{${filename}fig${index}}`
          );
        }
        // res[0] = res[0].replace("caption", newcaption);
        rename = `\\begin{figure}[t]\n\\centering\\includegraphics{${filename}f${index++}_pr.eps}\n\\caption{${
          newcaption[1]
        }}\n${newlabel}\n\\end{figure}`;
        data = data.replace(res[0], rename);
      }

      let capfig =
        /((?<=\.|\{)\s*Fig[ures]*\.*\s*~*-*|^\s*Fig[ures]*\.*\s*~*-*)\(?(\\(ref)\{([^}]*)\})\)?/gims;
      let res1;
      while ((res1 = capfig.exec(data)) !== null) {
        for (let n of figpair) {
          if (n.key === res1[res1.length - 1]) {
            data = data.replace(res1[0], `Figure~\\ref{${n.value}}`);
          }
        }
      }
      let smafig =
        /((?<=\w)\s*\(*Fig[ures]*\.*\s*~*-*)\(?(\\(ref)\{([^}]*)\})\)?\)*/gims;
      let res2;
      while ((res2 = smafig.exec(data)) !== null) {
        for (let n of figpair) {
          if (n.key === res2[res2.length - 1]) {
            data = data.replace(res2[0], ` fig.~\\ref{${n.value}}`);
          }
        }
      }
      return data;
    },
  },
  formatexample: {
    name: "Format e.g, i.e and et al",
    description: "Formats e.g ane i.e to italics by adding \\textit{}",
    action: (data, filename) => {
      if (data)
        data = data.replace(/e\.g\.*\s*/g, (i) => {
          if (i) {
            i = i.replace(i, "\\textit{e.g.}");
            return i;
          }
        });
      if (data)
        data = data.replace(/i\.e\.*\s*/g, (i) => {
          if (i) {
            i = i.replace(i, "\\textit{i.e.}");
            return i;
          }
        });
      if (data)
        data = data.replace(/\set\sal\.*\s*/g, (i) => {
          if (i) {
            i = i.replace(i, " {\\etal} ");
            return i;
          }
        });
      return data;
    },
  },
  citationstyling: {
    name: "Citation Styling",
    description:
      "Changes the cite reference along the tex body with respect to the bibitem reference.",
    action: (data, filename) => {
      let bibiliography = new RegExp(
        /\\begin\{thebibliography\}(.*?)\\end\{thebibliography\}/gms
      );
      let res = bibiliography.exec(data);
      let bibpair = [];
      let namereview = [];
      let index = 1;
      if (res && res[0].includes("\\bibitem")) {
        let bib = new RegExp(/\\bibitem\{([^}]*)\}/gm);
        let res2;
        while ((res2 = bib.exec(res[0])) !== null) {
          bibpair.push({
            key: res2[res2.length - 1],
            value: `${filename}bib${index}`,
          });
          data = data.replace(res2[0], `\\bibitem{${filename}bib${index++}}`);
        }
        // for sending the name and review content for processing if \\Name&\\Review tag is not present
        let id = 1;
        if (!res[0].includes("\\Name" || "\\Review")) {
          let namereviewreg = /(?<=\\bibitem\{\w*\})\n*(.*)?/gm;
          let res3;
          while ((res3 = namereviewreg.exec(res[0])) !== null) {
            namereview.push({ id: id++, name: res3[res3.length - 1] });
          }
          // send namereview array to the api for processing.
        } else {
          let nameandreview =
            /\\Name\{((?:[^{}]*(?:{(?:[^{}]*(?:{(?:[^{}]*(?:{[^{}]*})*[^{}]*)*})*[^{}]*)*})*[^{}]*)*)\}\s*\n*(\\Review\{(.*?\}\{.+?\}\{.+?\}\{.*?\}))*/gims;
          let res4;
          while ((res4 = nameandreview.exec(res[0])) !== null) {
            if (res4[0] === undefined) {
              namereview.push({ id: id++, name: `${res4[3]}` });
            } else if (res4[2] === undefined) {
              namereview.push({ id: id++, name: `${res4[1]}` });
            } else {
              namereview.push({ id: id++, name: `${res4[1]}, ${res4[3]}` });
            }
          }
        }
      } else {
        return data;
      }
      // for (let n of namereview) {
      // let data = citationref(n.name)
      // console.log(n.name);
      // }
      // change the citation reference as per bibitem reference
      let cite = new RegExp(/(\s*)~?\\cite\{([^}]*)\}/gm);
      let citeref;
      let newciteref;
      while ((citeref = cite.exec(data)) !== null) {
        console.log(citeref);
        if (citeref[1]) {
          citeref[0] = citeref[0].replace(" ", "");
        }
        if (citeref[0].includes("~") === false) {
          data = data.replace(citeref[0], `~${citeref[0]}`);
        }
        if (citeref[2].includes(",")) {
          let ref = [];
          let eachref = citeref[2].split(",");
          for (let n of bibpair) {
            for (let m of eachref) {
              if (m.includes(" ")) {
                m = m.replace(" ", "");
              }
              if (m.includes("\n")) {
                m = m.replace("\n", "");
              }
              if (n.key === m) {
                m = m.replace(m, n.value);
                ref.push(m);
              }
            }
          }
          newciteref = citeref[0].replace(citeref[2], ref);
          data = data.replace(citeref[0], newciteref);
        } else {
          for (let n of bibpair) {
            if (n.key === citeref[2]) {
              newciteref = citeref[0].replace(citeref[2], n.value);
              data = data.replace(citeref[0], newciteref);
            }
          }
        }
        console.log(namereview);
      }
      return data;
    },
  },
  // citationStyling: {
  //   name: "Citation styling",
  //   description: "",
  //   action: (data, filename) => {
  //     // return data;
  //     let citationfull = new RegExp(
  //       /\\begin{thebibliography}(.*)\\end{thebibliography}/gims
  //     );

  //     let bibitem;
  //     let index = 1;
  //     let bibpair = [];
  //     // let caption;
  //     // let rename;
  //     let res = citationfull.exec(data);
  //     // console.log(res);
  //     let excludebib;
  //     let bibtag;
  //     let bib = new RegExp(/\\bibitem\{[^}]+\}(.*?)(?=(\\bibitem|\$))/gims);
  //     if (res)
  //       while ((bibitem = bib.exec(res[0])) !== null) {
  //         // bibtag rename starts
  //         bibtag = new RegExp(/\\bibitem\{([^\}]*)\}/gims);
  //         bibtag = bibtag.exec(bibitem);
  //         bibpair.push({
  //           key: bibtag[bibtag.length - 1],
  //           value: `{${filename}bib${index}}`,
  //         });
  //         bibtag = bibtag[0].replace(
  //           bibtag[0],
  //           `\\bibitem{${filename}bib${index++}}`
  //         );
  //         // console.log(bibitem);

  //         // // Name tag code starts
  //         let finishedname;
  //         if (bibitem[0].includes("\\Name") == false) {
  //           excludebib = bibitem[1];
  //           let nametag;
  //           if (excludebib.includes("and")) {
  //             nametag = /(.*)and([^\,]*)/gm.exec(excludebib);
  //             nametag = nametag[0].replace("and", " <and> ");
  //             nametag = nametag.replace(
  //               /\b([\s?\~?A-Z\.]*\.?)\s*([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\b/gm,
  //               "$2 $1"
  //             );
  //             nametag = nametag.replace("<and>", "\\and");
  //             finishedname = nametag.replace(nametag, `\\Name{${nametag}}`);
  //           } else {
  //             nametag = /^([^\,]+)/gm.exec(excludebib);
  //             // console.log(nametag);
  //             nametag = nametag[0].replace(
  //               /\b([\s?\~?A-Z\.]*\.?)\s*([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\b/gm,
  //               "$2 $1"
  //             );
  //             finishedname = nametag.replace(nametag, `\\Name{${nametag}}`);
  //           }
  //         } else {
  //           let nametag = new RegExp(/\\Name\{([^\}]*)\}/gm);
  //           nametag = nametag.exec(bibitem[0]);
  //           nametag = nametag[1].replace(
  //             /\b([\s?\~?A-Z\.]*\.?)\s*([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\b/gm,
  //             "$2 $1"
  //           );
  //           finishedname = nametag.replace(nametag, `\\Name{${nametag}}`);
  //         }
  //         let bibname = [bibtag, finishedname].join("\n");

  //         // Reviewtag styling starts

  //         let finishedreview;
  //         if (bibitem[0].includes("\\Review") == false) {
  //           if (excludebib.includes("and")) {
  //             let reviewreg = new RegExp(
  //               /(and[^(\,\,?)]*(,|})\n?)(.*?)(?=(\\bibitem|$))/gms
  //             );
  //             let reviewtext = reviewreg.exec(excludebib);
  //             console.log(reviewtext);
  //             // (^\s*\w+[^(\d+|\{|\}|\(|\)|\\]*) regex first used for extracting journal name
  //             let journalname =
  //               /((([^\,]*))(?=\{\d)|((([^\,]*))(?=\s\d)))/gms.exec(
  //                 reviewtext[3]
  //               );
  //             // console.log(journalname);
  //             let year = /([1,2][6,7,8,9,0]\d\d?)\D$/gm.exec(reviewtext[3]);

  //             let volume;
  //             if (reviewtext[3].includes("{")) {
  //               volume = /(\{([^\}]*)\})/gm.exec(reviewtext[3]);
  //             } else if (reviewtext[3].includes("\\textbf")) {
  //               volume = /\\textbf(\{[^\}]*\})/gm.exec(reviewtext[3]);
  //             } else {
  //               volume = /(?<=([A-Za-z]\.?\s))(\w?\d+)/gm.exec(reviewtext[3]);
  //               console.log(volume);
  //             }

  //             let temp = reviewtext[3];
  //             let pagenumber = temp
  //               .replace(journalname[0], "")
  //               .replace(year[0], "");
  //             // console.log(pagenumber);
  //           } else {
  //             let reviewreg = new RegExp(
  //               /(^([^\,]+))(.*?)(?=(\\bibitem|$))/gims
  //             );
  //             let reviewtext = reviewreg.exec(excludebib);

  //             let journalname = /(^,?\s*\w+[^(\d+|\{|\}|\(|\)|\\]*)/gm.exec(
  //               reviewtext[3]
  //             );
  //             let year = /([1,2][6,7,8,9,0]\d\d?)\D$/gm.exec(reviewtext[3]);

  //             let volume;
  //             if (reviewtext[3].includes("{")) {
  //               volume = /(\{([^\}]*)\})/gm.exec(reviewtext[3]);
  //             } else if (reviewtext[3].includes("\\textbf")) {
  //               volume = /\\textbf(\{[^\}]*\})/gm.exec(reviewtext[3]);
  //               // console.log(volume);
  //             } else {
  //               volume = /(?<=([A-Za-z]\.?\s))(\w?\d+)/gm.exec(reviewtext[3]);
  //             }
  //           }
  //         } else {
  //         }
  //       }
  //     return data;
  //   },
  // },

  // // title to lowercase epl

  // // const titleLowerCase = (data) => {
  // //   data = data.replace(/\\title\{(.*?)\}/gm, (i) => {
  // //     i = i.replace(/[^\{.*?\}]/, (k) => {
  // //       k = k.toLowerCase();
  // //       k = k.replace(/^\w/, (l) => {
  // //         l.toUpperCase();
  // //       });
  // //       return k;
  // //     });
  // //     return i;
  // //   });
  // //   console.log(data);
  // // };
};

export default epl;
