import React from "react";
// import PdfViewer from "./PdfViewer";
import { Heading } from "@chakra-ui/react";

function RawLog(props) {
  return (
    <>
      {props.data ? (
        <pre>{props.data}</pre>
      ) : (
        <Heading style={{ textAlign: "center" }}>Nothing Found</Heading>
      )}
    </>
  );
}

export default RawLog;
