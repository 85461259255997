import React from "react";
import styles from "./Feature1.module.css";
import { Heading } from "@chakra-ui/react";
import Advantagenew from "./Advantagenew";
import UniqueFeaturenew from "./UniqueFeaturenew";
import Publishernew from "./Publishernew";
import KeyAspectsnew from "./KeyAspectsnew";

const Feature1 = ({ isTablet }) => {
  // const [show, setShow] = useState(false);

  // const handleclick = (e) => {
  //   let video = document.querySelector(`${styles.video}`);
  //   // setShow(true);
  // };

  return (
    <div className={styles.feature_outer}>
      <div className={styles.feature_content}>
        {/* <img src={process.env.PUBLIC_URL + "/images/Redesignpics/Vector.png"} /> */}
        <div className={styles.content}>
          <div className={styles.heading}>
            <Heading size="xl" fontFamily="Georgia" fontWeight={400}>
              Features
            </Heading>
          </div>
          <div className={styles.feature_media}>
            <div className={styles.feature_video}>
              <video
                src={
                  process.env.PUBLIC_URL +
                  "/images/Redesignpics/Featurevideo1.webm"
                }
                className={styles.video}
                controls
                width="420"
                height="auto"
              ></video>
              {/* {show ? <div className={styles.playbtn}></div> : <></>} */}
            </div>
            <div className={styles.feature_para}>
              <p>
                LaTex is comparatively easier for writing equations and
                technical matters for an advanced scholarly publishing in STM
                domain. The difficulty may arise when it comes to the proofing
                stage. This is where EditLaTeX becomes your aid for seamless
                proofing.
              </p>
            </div>
          </div>
        </div>
        {/* <img src={process.env.PUBLIC_URL + "/images/Redesignpics/Vector.png"} /> */}
      </div>
      <div className={styles.features_layout}>
        <div className={`${styles.heading1} ${styles.pos1}`} id="#fea1">
          <p>Advantages</p>
        </div>
        <div className={styles.feature1}>
          <Advantagenew isTablet={isTablet} />
        </div>
        <div className={`${styles.heading1} ${styles.pos2}`} id="#fea2">
          <p>Unique features</p>
        </div>
        <div className={styles.feature1}>
          <UniqueFeaturenew isTablet={isTablet} />
        </div>
        <div className={`${styles.heading1} ${styles.pos3}`} id="#fea3">
          <p>For Publisher</p>
        </div>
        <div className={styles.feature1}>
          <Publishernew isTablet={isTablet} />
        </div>
        <div className={`${styles.heading1} ${styles.pos4}`} id="#fea3">
          <p>Key Aspects</p>
        </div>
        <div className={styles.feature1}>
          <KeyAspectsnew isTablet={isTablet} />
        </div>
      </div>
    </div>
  );
};

export default Feature1;
