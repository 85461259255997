import React from "react";
import styles from "./Form.module.css";
import { Heading } from "@chakra-ui/react";

const FormNew = ({ isMobile, isTablet }) => {
  return (
    <div className={styles.form_outer}>
      <div className={styles.contact_form}>
        <Heading
          fontFamily="Georgia"
          size="lg"
          textAlign="left"
          fontWeight={400}
        >
          Let us know how we can help
        </Heading>
        {isTablet ? (
          <div className={styles.more_outer}>
            <p>
              Want to learn more about the EditLatex? Submit this form and our
              team will contact soon.
            </p>
          </div>
        ) : (
          <></>
        )}
        <form>
          <div className={styles.input_box}>
            <div className={styles.input_divs}>
              <input type="text" placeholder="First Name*" required />
            </div>
            <div className={styles.input_divs}>
              <input type="text" placeholder="Last Name" required />
            </div>
          </div>
          <div className={styles.input_box}>
            <div className={styles.input_divs}>
              <input type="email" placeholder="Email ID*" required />
            </div>
            <div className={styles.input_divs}>
              <input type="number" placeholder="Phone Number*" required />
            </div>
          </div>
          <div className={styles.input_box}>
            <div className={`${styles.input_divs} ${styles.input_msg}`}>
              <textarea type="text" placeholder="Your Message*" required />
            </div>
          </div>
          <div className={styles.form_checkbox}>
            <input type="checkbox" required />
            <p>
              I agree that EditLaTeX may contact me via email, phone and text in
              regards to my application or marketing updates. I can opt-out by
              following instructions in the{" "}
              <span style={{ color: "#00BFF2", cursor: "pointer" }}>
                Privacy Policy
              </span>
            </p>
          </div>
          <div className={styles.btnbox}>
            <button type="submit" className={styles.submit_btn}>
              Submit
            </button>
          </div>
        </form>
      </div>
      {!isTablet ? (
        <>
          <div className={styles.veriline}>
            <hr></hr>
          </div>
          <div className={styles.more_outer}>
            <p>
              Want to learn more about the EditLatex? Submit this form and our
              team will contact soon.
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FormNew;
